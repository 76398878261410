import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS} from "../common/Strings";
import {Link} from "react-router-dom";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import Copyright from "../common/Copyright";



class AccountSecurityScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        var user = undefined
        var email = undefined
        var userStr = localStorage.getItem("ppuser");
        if (user !== null) {
            user = JSON.parse(userStr)
            email = user.email
        }
        this.state = {
            user: user,
            email : email
        };
    }


    componentDidMount() {
    }

    onChangeEmailClicked() {
        if (this.state.saving) {
            return
        }

        this.setState({saving : true})

        var formData = new FormData();
        formData.append("email", this.state.email);

        Axios.post(ENDPOINTS.settings.saveUserEmail, formData)
            .then((response) => {
                this.setState({saving : false})

                if (response.data.success) {
                    this.showModal("Saved", "Your new email address has been saved.")

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save email at this time. Please try again later. [105]")
            });

    }

    onChangePasswordClicked() {
        if (this.state.saving) {
            return
        }

        if (!this.state.existingPassword || this.state.existingPassword.length === 0){
            this.showModal("Error", "Please enter your existing password.")
            return;
        }
        if (!this.state.password || this.state.password.length < 6){
            this.showModal("Error", "Please enter a new password at least 6 characters long.")
            return;
        }
        if (this.state.password !== this.state.confirmPassword){
            this.showModal("Error", "Please make sure password and confirm password fields match.")
            return;
        }
        this.setState({saving : true})

        var formData = new FormData();
        formData.append("newPassword", this.state.password);
        formData.append("oldPassword", this.state.existingPassword);

        Axios.post(ENDPOINTS.settings.changeUserPassword, formData)
            .then((response) => {
                this.setState({saving : false})

                if (response.data.success) {
                    this.showModal("Saved", "Your new password address has been saved.")

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save password at this time. Please try again later. [105]")
            });
    }


    render() {



        return(
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.SETTINGS} />

                <div className="main-body">

                    <div className="grid-container">

                        <div className="grid-x grid-padding-x header-container">
                            <div className="large-12 cell">
                                <h1>Account Settings</h1>
                            </div>
                        </div>

                        <div className="grid-x grid-padding-x settings-menu-container">
                            <div className="large-12 cell">
                                <Link to={"/settings/"}><button className="btn">Company Details</button></Link> <button className="btn selected">Security</button> {this.props.user.isAdmin === 1 && <Link to={"/settings/users"}><button className="btn">Users</button></Link>} {this.props.user.isAdmin === 1 && <Link to={"/settings/partners"}><button className="btn">Partners</button></Link>}

                            </div>
                        </div>

                        <div className="grid-x grid-padding-x setting-container">
                            <div className="large-12 cell">
                                <h2>Email Change</h2>
                                <div className="setting-wrap">
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell large-12">

                                            <span className="label">Email</span>
                                            <input type="text" className="settings-field" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                            <button type="submit" className="btn" onClick={ () => this.onChangeEmailClicked() } >{this.state.saving ? "Saving..." : "Change Email"}</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="grid-x grid-padding-x setting-container">
                            <div className="large-12 cell">
                                <h2>Password Change</h2>
                                <div className="setting-wrap">
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell large-12">

                                            <span className="label">Existing Password</span>
                                            <input type="password" className="settings-field" name="existingPassword" placeholder="Enter Your Existing Password" value={this.state.existingPassword} onChange={this.handleChange} />

                                            <span className="label">New Password</span>
                                            <input type="password" className="settings-field" name="password" placeholder="New Password" value={this.state.password} onChange={this.handleChange} />

                                            <span className="label">Confirm New Password</span>
                                            <input type="password" className="settings-field" name="confirmPassword" placeholder="New Password" value={this.state.confirmPassword} onChange={this.handleChange} />

                                            <button type="submit" className="btn" onClick={ () => this.onChangePasswordClicked() } >{this.state.saving ? "Saving..." : "Change Password"}</button>

                                        </div>
                                    </div>

                                </div> <div className="settings-help">If you need any help, get in touch - <a href={"mailto:support@gasapp.co.uk"}>support@gasapp.co.uk</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <Copyright />
                        </div>
                        <AlertModal
                            open={ (this.state.modal != null) ? this.state.modal.open : false }
                            title={ (this.state.modal != null) ? this.state.modal.title : "" }
                            content={ (this.state.modal != null) ? this.state.modal.content : [] }
                            buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                            dismissHandler={ () => this.modalWasDismissed() }
                        />
                    </>)

    }
}

export default AccountSecurityScreen