import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS} from "../common/Strings";
import {Link} from "react-router-dom";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import Paper from "@material-ui/core/Paper/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Copyright from "../common/Copyright";


class AccountUsersScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            users : [],
            page: 0,
            userType: 0,
            rowsPerPage : 25
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    }


    componentDidMount() {

        this.getUsers()
        this.getPartners()
    }


    userClicked(idx) {
        let selUser = this.state.matchingUsers[idx]
        this.setState({selectedUser : selUser,
            selectedUser_name : selUser.name,
            selectedUser_email : selUser.email,
            selectedUser_password : "",
            selectedUser_userType : selUser.isAdmin,
            selectedUser_partnerID : selUser.partnerID,
        })
    }

    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());

    }

    updateMatching() {
        var matching = []
        if (this.state.search)
        {
            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.users.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.users[c].name.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.users[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.users
        }

        var userTble = matching.map((row, c) => {

            return <tr key={c}  onClick={(idx) => this.userClicked(c)}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.isAdmin === 1 ? "Access to all partners": row.partner}</td>
                <td>{row.dateCreated}</td>
                <td>{row.isAdmin === 1 ? "Administrator": "Standard"}</td>

            </tr>

        })

        this.setState({matchingUsers : matching, userTableData : userTble})
    }


    getUsers() {
        var formData = new FormData();

        Axios.post(ENDPOINTS.settings.getUsers, formData)
            .then((response) => {
                if (response.data.success) {


                    this.setState({
                        users: response.data.users
                    }, () => this.updateMatching())
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch users at this time. Please try again later. [101]")
            });
    }

    getPartners() {
        var formData = new FormData();

        Axios.post(ENDPOINTS.settings.getPartners, formData)
            .then((response) => {
                if (response.data.success) {

                    var partnerID = -1
                    let partners = response.data.partners.map((row, c) => {

                        if (partnerID < 0 ) {
                            partnerID = row.id
                        }

                        return <option key={c} value={row.id}>{row.name}</option>

                    })

                    this.setState({
                        partners: partners,
                        partnerID : partnerID
                    })
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch users at this time. Please try again later. [101]")
            });
    }

    addUser() {
        this.setState({addingUser : true})
    }

    cancelAdd() {
        this.setState({addingUser : false, name : "", email : "", password : ""})
    }

    cancelUpdate() {
        this.setState({selectedUser : undefined, selectedUser_name : "", selectedUser_email : "", selectedUser_password : ""})
    }


    onSaveClicked() {
        if (this.state.saving) {
            return
        }

        this.setState({saving : true})

        var formData = new FormData();
        if (this.state.selectedUser) {
            formData.append("userID", this.state.selectedUser.id);
            formData.append("partnerID", this.state.selectedUser_partnerID);
            formData.append("userType", this.state.selectedUser_userType);
            formData.append("name", this.state.selectedUser_name);
            formData.append("email", this.state.selectedUser_email);
            if (this.state.selectedUser_password.length > 0){
                formData.append("password", this.state.selectedUser_password);
            }
        } else {
            formData.append("partnerID", this.state.partnerID);
            formData.append("userType", this.state.userType);
            formData.append("name", this.state.name);
            formData.append("email", this.state.email);
            formData.append("password", this.state.password);
        }


        Axios.post(ENDPOINTS.settings.saveUserDetails, formData)
            .then((response) => {
                this.setState({saving : false})

                if (response.data.success) {
                    this.showModal("Saved", "Your changes have been saved.")
                    this.getUsers()
                    this.cancelAdd()
                    this.cancelUpdate()

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save partner info at this time. Please try again later. [104]")
            });

    }


    askToDeleteUser() {
        let buttons = [
            {
                label : "Yes, delete",
                style : "btn-default",
                click : () => {
                    this.deleteUser();
                    this.hideModal();
                }
            },
            {
                label : "No, cancel",
                style : "btn-default",
                click : () => {
                    this.hideModal();
                }
            }
        ];
        this.showModal("Are You Sure?", "This can not be undone!", buttons)
    }

    deleteUser() {

        var formData = new FormData();

        formData.append("userID", this.state.selectedUser.id);

        Axios.post(ENDPOINTS.settings.deleteUser, formData)
            .then((response) => {

                if (response.data.success) {
                    this.showModal("Delete", "The user has been deleted.")
                    this.getUsers()
                    this.cancelAdd()
                    this.cancelUpdate()

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save partner info at this time. Please try again later. [104]")
            });
    }


    render() {

        var mainContent = <div>not set!</div>

        if (this.state.addingUser) {
            mainContent = <div className="grid-x grid-padding-x setting-container">
                <div className="large 12 cell ">
                    <h2>New User Details</h2>
                    <div className="setting-wrap">
                        <div className="grid-x grid-padding-x">
                            <div className="cell large-12">

                                <span className="label">Name</span>
                                <input type="text" className="settings-field"  name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange} />

                                <span className="label">Email</span>
                                <input type="text" className="settings-field" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />

                                <span className="label">Password</span>
                                <input type="text" className="settings-field" name="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />

                                <span className="label">User Type</span>
                                <select  className="settings-field" value={this.state.userType} name="userType" onChange={this.handleChange}>
                                    <option value={0}>Standard</option>
                                    <option value={1}>Admin</option>
                                </select>
                                <span className="label">Select Partner</span>
                                <select  className="settings-field" value={this.state.partnerID} name="partnerID" onChange={this.handleChange}>
                                    {this.state.partners}
                                </select>
                                <button type="submit" className="btn" onClick={ () => this.onSaveClicked() } >{this.state.saving ? "Saving..." : "Add User"}</button> <button type="submit" className="btn" onClick={ () => this.cancelAdd() } >Cancel</button>
                            </div></div></div>
                </div>
            </div>
        } else if (this.state.selectedUser) {
            mainContent = <div className="grid-x grid-padding-x setting-container">
                <div className="large 12 cell ">
                    <h2>Edit User Details</h2>
                    <div className="setting-wrap">
                        <div className="grid-x grid-padding-x">
                            <div className="cell large-12">

                                <span className="label">Name</span>
                                <input type="text" className="settings-field"  name="selectedUser_name" placeholder="Name" value={this.state.selectedUser_name} onChange={this.handleChange} />

                                <span className="label">Email</span>
                                <input type="text" className="settings-field" name="selectedUser_email" placeholder="Email" value={this.state.selectedUser_email} onChange={this.handleChange} />

                                <span className="label">Password (leave blank to keep existing password)</span>
                                <input type="text" className="settings-field" name="selectedUser_password" placeholder="Password" value={this.state.selectedUser_password} onChange={this.handleChange} />

                                <span className="label">User Type</span>
                                <select  className="settings-field" value={this.state.selectedUser_userType} name="selectedUser_userType" onChange={this.handleChange}>
                                    <option value={0}>Standard</option>
                                    <option value={1}>Admin</option>
                                </select>
                                <span className="label">Select Partner</span>
                                <select  className="settings-field" value={this.state.selectedUser_partnerID} name="selectedUser_partnerID" onChange={this.handleChange}>
                                    {this.state.partners}
                                </select>
                                <button type="submit" className="btn" onClick={ () => this.onSaveClicked() } >{this.state.saving ? "Saving..." : "Save Changes"}</button> <button type="submit" className="btn" onClick={ () => this.cancelUpdate() } >Cancel</button>

                                <button style={{float : "right"}} type="submit" className="btn" onClick={ () => this.askToDeleteUser() } >Delete User</button>
                            </div></div></div>
                </div>
            </div>
        } else {
            mainContent =<div className="grid-x grid-padding-x table-container">
                <div className="large 12 cell ">
                    <h2>Users</h2>
                    <button className="btn add-user" onClick={() => this.addUser()}>Add a User</button>
                </div>
                <div className="large-6 cell table-total">
                    {this.state.users &&
                    <span>{this.state.users.length} Total Users</span>
                    }
                </div>
                <div className="large-6 cell">
                    <input type="text" placeholder="Search" className="search" onChange={this.handleSearchTextChange}  name="search" value={this.state.search}  />
                </div>

                <div className="large-12 cell table">
                    <table>
                        <thead>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Partner</th>
                        <th>Date Created</th>
                        <th>User Type</th>
                        </thead>
                        <tbody>
                        {this.state.userTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.userTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </tbody>
                    </table>
                </div>

                <div className="small-12 cell table-nav">
                    {this.state.userTableData &&
                    <span>Showing {this.state.userTableData.length < this.state.rowsPerPage ? this.state.userTableData.length : this.state.rowsPerPage} of {this.state.users.length}</span>
                    }
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component={Paper}
                        count={this.state.userTableData === undefined ? 0 : this.state.userTableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />
                </div>
            </div>
        }

        return (  <>
            <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.SETTINGS} />


            <div className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="large-12 cell">
                            <h1>Account Settings</h1>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x settings-menu-container">
                        <div className="large-12 cell">
                            <Link to={"/settings/"}><button className="btn">Company Details</button></Link> <Link to={"/settings/security"}><button className="btn">Security</button></Link> {this.props.user.isAdmin === 1 && <button className="btn selected">Users</button>} {this.props.user.isAdmin === 1 && <Link to={"/settings/partners"}><button className="btn">Partners</button></Link>}

                        </div>
                    </div>

                    {mainContent}

                </div>
            </div>
            <div className="footer">
                <Copyright />
            </div>
            <AlertModal
                open={ (this.state.modal != null) ? this.state.modal.open : false }
                title={ (this.state.modal != null) ? this.state.modal.title : "" }
                content={ (this.state.modal != null) ? this.state.modal.content : [] }
                buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                dismissHandler={ () => this.modalWasDismissed() }
            />
            </>)


    }
}

export default AccountUsersScreen