import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import {Button} from "react-foundation";
import {Bar} from "react-chartjs-2";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import Paper from "@material-ui/core/Paper/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import {DATA} from "../common/Strings";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Copyright from "../common/Copyright";

class BreaktimeScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            comments: "-",
            posts: "-",
            barChartData: [],
            page: 0,
            rowsPerPage : 10,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            data,
            search:""
        };

        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    }

    exportData() {

        let report = REPORTS.BREAKTIME
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }


    dateOrDaysChanged() {
        this.getAllBreaktimeData()
    }


    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());

    }


    updateMatching() {

        var matching = []

        if (this.state.search)
        {

            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.postData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.postData[c].body.toLowerCase().includes(searchTerms[i].toLowerCase()) || this.state.postData[c].name.toLowerCase().includes(searchTerms[i].toLowerCase())) {
                        matching.push(this.state.postData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.postData
        }

        var postsTble = matching.map((row, c) => {

            return <tr key={c} onClick={(idx) => this.postClicked(c)}>
                <td>{row.body}</td>
                <td>{row.name}</td>
                <td>{row.dateCreated}</td>
                <td>{row.images > 0 ? "Yes":"No"}</td>
                <td>{row.likes}</td>
                <td>{row.comments}</td>

            </tr>

        })
        this.setState({postsTableData : postsTble, matchingPosts : matching})
    }



    getAllBreaktimeData() {
        this.getBreaktimeQuickStats()
        this.getBreaktimePosts()
        this.getPostsOverTime()
    }

    postClicked(idx) {
        this.setState({
            selectedPost: idx
        }, () => this.updateCommentList());

    }

    updateCommentList() {

        var formData = new FormData();
        var source = DATA.GASAPP
        if (this.state.matchingPosts[this.state.selectedPost].db.includes("plumb")) {
            source = DATA.PLUMBAPP
        }
        formData.append("postID", this.state.matchingPosts[this.state.selectedPost].id);
        formData.append("source", source);
        Axios.post(ENDPOINTS.breakTime.getBreaktimeComments, formData)
            .then((response) => {
                if (response.data.success) {

                    var comntsTrble = response.data.comments.map((row, c) => {

                        return <div key={c} className = "comment-wrap">
                            <div  className="grid-x grid-padding-x" >
                                <div className="cell medium-12 large-12" >
                                    <h3>{row.name}</h3>
                                    <span>&nbsp;&nbsp;{row.dateCreated}</span>
                                    <p> {row.body}</p>
                                </div>
                            </div>
                        </div>

                    })
                    this.setState({
                        commentsList: comntsTrble
                    })
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    getBreaktimePosts() {
        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.breakTime.getBreaktimePosts, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                       postData: response.data.posts
                    }, () => this.updateMatching())
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }

    getPostsOverTime(){
        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.breakTime.getPostsOverTime, formData)
            .then((response) => {
                if (response.data.success) {

                    const data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                label: '',
                                data: response.data.values,
                                backgroundColor: this.state.gradient,
                                borderWidth: 1,
                            },
                        ],
                    };

                    this.setState({
                        barChartData: data
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }

    getBreaktimeQuickStats() {
        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.breakTime.getBreaktimeQuickStats, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        posts: response.data.posts,
                        comments: response.data.comments,
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    componentDidMount() {
        var canvas = document.getElementById("barchart");
        var ctx = canvas.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 0, 322);
        gradient.addColorStop(0, 'rgba(98,209,25,1)');
        gradient.addColorStop(1, 'rgba(15,119,44,1)');

        this.setState({
            gradient : gradient
        }, () => this.getAllBreaktimeData())

    }

    clearPost() {

        this.setState({selectedPost: undefined, commentList: undefined})
    }

    changePost(change) {
        var newPost = this.state.selectedPost + change
        if (newPost < 0) {
            newPost = this.state.matchingPosts.length -1
        }
        if (newPost >= this.state.matchingPosts.length) {
            newPost = 0
        }

        this.setState({selectedPost : newPost, commentList: undefined}, () => this.updateCommentList())
    }


    render() {

        var body = [];

        if (this.state.selectedPost !== undefined){
            body.push(<div className="main-body">
                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearPost() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>
                            <h1>Breaktime</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changePost(-1) }></span>
                                <h2>{this.state.matchingPosts[this.state.selectedPost].name} - {this.state.matchingPosts[this.state.selectedPost].dateCreated}</h2>
                                <span className="next" onClick={ () => this.changePost(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>

                <div class="grid-x grid-padding-x detail-container">
                <div class="small-12 cell">
                    <h2>Post Details</h2>
                    <div class="detail-wrap">
                        <div class="grid-x grid-padding-x">
                            <div class="cell medium-12 small-6">
                                <p>
                                    {this.state.matchingPosts[this.state.selectedPost].body}
                                </p>

                            </div>
                            <div class="cell medium-12 small-6">
                                {this.state.images &&
                                <div className="image-gal">
                                    <img src="https://www.juliebergan.no/sites/g/files/g2000006326/f/sample-4.jpg"
                                         alt=""/>
                                    <img src="https://www.juliebergan.no/sites/g/files/g2000006326/f/sample-4.jpg"
                                         alt=""/>
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                </div>


                <div className="grid-x grid-padding-x comment-container">
                    <div className="small-12 cell">
                        <h2>Comments - {this.state.matchingPosts[this.state.selectedPost].comments}</h2>
                        <h2>Likes - {this.state.matchingPosts[this.state.selectedPost].likes}</h2>
                        {this.state.commentsList}
                    </div>
                </div>


                </div>



                </div>)

        } else {
            body.push(<div id="divToPrint"  className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Breaktime</h1>
                        </div>
                        <div className="small-3">
                            <Button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt=""/>Export Data</Button>

                        </div>
                    </div>


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div> 
                    

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>
                                Breaktime Post Stats during this time period</h2>
                            <div className="quick-stat-wrap">

                                <div className="stat-wrap stat-wrap-2">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.posts.toLocaleString()}</div>
                                        <div className="label">Posts</div>
                                    </div>
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.comments.toLocaleString()}</div>
                                        <div className="label">Comments</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x table-container">
                        <div className="small-12 cell ">
                            &nbsp; 

                        </div>
                        <div className="small-6 cell table-total">
                        </div>
                        <div className="small-6 cell">
                            <input type="text" className="search" placeholder={"Search"}  name="search" value={this.state.search} onChange={this.handleSearchTextChange} />
                        </div>

                        <div className="small-12 cell table">
                            <table>
                                <thead>
                                <th>Message</th>
                                <th>User</th>
                                <th>Date Posted</th>
                                <th>Image</th>
                                <th>Likes</th>
                                <th>No of Comments</th>
                                </thead>
                                <tbody>
                                {this.state.postsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.postsTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </tbody>
                            </table>
                            <div className="small-12 cell table-nav">
                                <TablePagination
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.postsTableData === undefined ? 0 : this.state.postsTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>


                        </div>

                        
                    </div>


                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <Bar id={"barchart"}
                                 data={this.state.barChartData}
                                 height={333}
                                 options={
                                     {
                                         plugins: {
                                             legend: {
                                                 display: false,
                                             },
                                         },
                                         maintainAspectRatio: false,
                                         scales: {
                                             xAxes: [{
                                                 stacked: false
                                             }],
                                             yAxes: [{
                                                 stacked: false
                                             }]
                                         }
                                     }
                                 }
                            />
                        </div>
                    </div>


                </div>

            </div>)
        }





        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.BREAKTIME}/>

                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>

        )


    }
}

export default BreaktimeScreen
