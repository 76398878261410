import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, DATA} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import {Button} from "react-foundation";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Copyright from "../common/Copyright";

class SocialScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");
        var user = undefined
        var userStr = localStorage.getItem("ppuser");
        if (user !== null) {
            user = JSON.parse(userStr);
        }

        this.state = {
            overDays: 30,
            posts: "-",
            data,
            app: "Both",
            user: user,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
            partners : [],
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.additionalImageChanged = this.additionalImageChanged.bind(this);
        this.postImageChanged = this.postImageChanged.bind(this);
        this.handlePartnerChange = this.handlePartnerChange.bind(this);

    }




    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, () => this.updatePostsElems());

    }

    setViewType(type) {

        this.setState({view: type})
    }


    getAllSocialData() {
        this.getQuickStats()
        this.getPostsTableData()
    }

    getPostsTableData() {
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        formData.append("platform", this.props.platform);

        if (this.state.overDays < 0) {
            formData.append("fromDate", this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.social.getPostsData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        postsData: response.data.posts,
                    }, () => this.updatePostsElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    updatePostsElems() {

        var matching = []

        if (this.state.search) {

            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.postsData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.postsData[c].title.toLowerCase().includes(searchTerms[i].toLowerCase())) {
                        matching.push(this.state.postsData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.postsData
        }


        var bigPostsTarble = matching.map((row, c) => {

            return <tr key={c} onClick={(idx) => this.postClicked(c)}>
                <td>{row.title}</td>
                <td>{row.postDate}</td>
                <td>{row.images}</td>
            </tr>

        })



        this.setState({ bigPostsTable: bigPostsTarble})
    }



    componentDidUpdate(prevProps) {

        if (prevProps.platform !== this.props.platform) {
            this.clearPost()
            this.getAllSocialData()
        }
    }

    postClicked(idx) {

        this.setState({
            selectedPost: idx,
            changedTitle : this.state.postsData[idx].title,
            changedApp : this.state.postsData[idx].app
        }, () => this.updatePostDetails());

    }

    updatePostDetails() {

        var formData = new FormData();

        formData.append("postID", this.state.postsData[this.state.selectedPost].id);

        Axios.post(ENDPOINTS.social.getPostsImages, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        postImages: response.data.images,
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }


    editTitle() {

        var formData = new FormData();

        formData.append("postID", this.state.postsData[this.state.selectedPost].id);
        formData.append("title", this.state.changedTitle);
        formData.append("app", this.state.changedApp);

        Axios.post(ENDPOINTS.social.editPost, formData)
            .then((response) => {
                if (response.data.success) {

                    this.getAllSocialData()
                    this.showModal("Success", "Post updated successfully")
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }



    clearPost() {

        this.setState({selectedPost: undefined,postImages:undefined})
    }

    changePost(change) {
        var newPost = this.state.selectedPost + change

        if (newPost < 0) {
            newPost = this.state.postsData.length - 1
        }
        if (newPost >= this.state.postsData.length) {
            newPost = 0
        }

        this.setState({selectedPost: newPost, postImages: undefined}, () => this.updatePostDetails())
    }



    getQuickStats(){
         var formData = new FormData();

         formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        formData.append("platform", this.props.platform);

         Axios.post(ENDPOINTS.social.getQuickStats, formData)
             .then((response) => {
                 if (response.data.success) {

                     this.setState({
                         posts: response.data.posts
                     });
                 } else {
                     console.log(response.data.error);

                 }
             })
             .catch(error => {
                 console.log("ERROR");
                 console.log(error);
             });



    }

    addPost() {

        if (!this.state.postImage)
        {
            return
        }
        var formData = new FormData();
        formData.append("image", this.state.postImage);
        formData.append("title", this.state.title);
        formData.append("app", this.state.app);
        formData.append("platform", this.props.platform);

        var context = this;

        Axios.post(ENDPOINTS.social.uploadImage, formData, {
            onUploadProgress : function(data) {
                if (data.lengthComputable) {
                    var percent = ((data.loaded / data.total) * 100);

                    console.log("UPLOAD: " + percent + "%");
                    context.setState({
                        uploadProgressPercent : percent
                    });
                }
            }
        })
            .then((res) => {

                if (res.data.success) {
                    context.setState({
                        postImage : undefined
                    });
                    this.getAllSocialData()
                    this.functionThatResetsTheFileInput()
                    this.showModal("Success", "Upload was successful");

                } else {
                    var msg = "Error uploading image, please check your connection and try again."
                    if (res.data.error) {
                        msg = res.data.error.replace(/<\/?[^>]+(>|$)/g, "");
                    }
                    alert(msg)
                    this.setState({
                        saving: false
                    })

                }
            })
            .catch(function(err) {
                console.log(err);
                alert("Error", "An unknown error has occurred. Please try again later. [1170]")
                context.setState({
                    isUploading : false
                });
            });
    }




    addAnotherImage() {

        if (!this.state.additionalImage)
        {
            return
        }
        var formData = new FormData();
        formData.append("image", this.state.additionalImage);
        formData.append("postID", this.state.postsData[this.state.selectedPost].id);

        var context = this;

        Axios.post(ENDPOINTS.social.uploadImage, formData, {
            onUploadProgress : function(data) {
                if (data.lengthComputable) {
                    var percent = ((data.loaded / data.total) * 100);

                    console.log("UPLOAD: " + percent + "%");
                    context.setState({
                        uploadProgressPercent : percent
                    });
                }
            }
        })
            .then((res) => {

                if (res.data.success) {
                    context.setState({
                        additionalImage : undefined
                    });
                    this.updatePostDetails()
                    this.functionThatResetsTheFileInput()
                    this.showModal("Success", "Upload was successful");

                } else {
                    var msg = "Error uploading image, please check your connection and try again."
                    if (res.data.error) {
                        msg = res.data.error.replace(/<\/?[^>]+(>|$)/g, "");
                    }
                    alert(msg)
                    this.setState({
                        saving: false
                    })

                }
            })
            .catch(function(err) {
                console.log(err);
                alert("Error", "An unknown error has occurred. Please try again later. [1170]")
                context.setState({
                    isUploading : false
                });
            });
    }


    functionThatResetsTheFileInput() {
        let randomString = Math.random().toString(36);
        let randomString2 = Math.random().toString(36);

        this.setState({
            additionalInputKey: randomString,
            postInputKey: randomString2,
            title : undefined
        });
    }


    dateOrDaysChanged() {
        this.getAllSocialData()
    }

    componentDidMount() {
        this.getAllSocialData()
        this.getPartners()

    }

    getPartners() {
        var formData = new FormData();

        Axios.post(ENDPOINTS.settings.getPartners, formData)
            .then((response) => {
                if (response.data.success) {

                    let partners = response.data.partners.map((row, c) => {

                        return <option key={c} value={row.id}>{row.name}</option>

                    })

                    this.setState({
                        partners: partners,
                        partnerID : this.state.user.partnerId
                    })
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch users at this time. Please try again later. [101]")
            });
    }


    handlePartnerChange(event) {

        const target = event.target
        const value = target.value


        var formData = new FormData();
        formData.append("partnerID", value);

        Axios.post(ENDPOINTS.settings.adminChangePartner, formData)
            .then((response) => {
                if (response.data.success) {

                    let user = response.data.user

                    localStorage.setItem("ppuser", JSON.stringify(user));
                    if (user.partner.app === DATA.PLUMBAPP) {
                        localStorage.setItem("ppdata", DATA.PLUMBAPP);
                    } else {
                        if (user.partner.app === DATA.GASAPP) {
                            localStorage.setItem("ppdata", DATA.GASAPP);
                        } else {
                            var data = localStorage.getItem("ppdata");
                            if (!data){
                                localStorage.setItem("ppdata", DATA.GASAPP);
                            }

                        }

                    }
                    window.location.reload();

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not update partner info at this time. Please try again later. [104]")
            });




    }

    imageClicked(idx) {
        window.open(this.state.postImages[idx].publicFullPath,'_blank');
    }

    deletePost() {

        if (window.confirm("Are you sure? This can not be undone!")) {
            var formData = new FormData();

            formData.append("postID", this.state.postsData[this.state.selectedPost].id);

            Axios.post(ENDPOINTS.social.deletePost, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.clearPost()
                        this.getAllSocialData()
                        this.showModal("Success", "Post deletion was successful");

                    } else {
                        console.log(response.data.error);

                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);
                });
        }

    }



    deleteImageClicked(idx) {

        if (window.confirm("Are you sure? This can not be undone!")) {
            var formData = new FormData();

            formData.append("imageID", this.state.postImages[idx].id);

            Axios.post(ENDPOINTS.social.deleteImage, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.updatePostDetails()
                        this.showModal("Success", "Image deletion was successful");

                    } else {
                        console.log(response.data.error);

                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);
                });
        }
    }
    postImageChanged(event){
        if (event.target.files.length > 0) {
            var uploadImage = event.target.files[0];

            if (uploadImage != null) {
                this.setState({
                    postImage: uploadImage
                });
            }
        }
    }
    additionalImageChanged(event){
        if (event.target.files.length > 0) {
            var uploadImage = event.target.files[0];

            if (uploadImage != null) {
                this.setState({
                    additionalImage: uploadImage
                });
            }
        }
    }


    render() {

        var body = [];

        if (this.state.selectedPost !== undefined) {
            body.push(<div className="main-body">

                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearPost() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>

                            <h1>{this.props.platform} Uploads</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changePost(-1) }></span>
                                <h2>{this.state.postsData[this.state.selectedPost].title.substring(0,30)} {this.state.postsData[this.state.selectedPost].title.length > 30 && "..."}</h2>
                                <span className="next" onClick={ () => this.changePost(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>


                    <div className="grid-x grid-padding-x detail-container">
                        <div className="small-12 cell">
                            <h2>Details</h2>
                            <div className="detail-wrap">
                                <div className="grid-x grid-padding-x">
                                    <div className="cell small-12">
                                        <h3>Title</h3>
                                        <p>{this.state.postsData[this.state.selectedPost].title}</p>

                                        {this.state.user.isAdmin === 1 &&
                                        <>
                                            <div className="admin-head">
                                                <h2>Admin - Edit Item</h2>
                                            </div>
                                            <div className="admin-box">
                                                <span className="admintitles">App</span>

                                                {(this.state.user.partner.app && this.state.user.partner.app === DATA.BOTH) ?

                                                    <select className="settings-field field-space" value={this.state.changedApp} name="changedApp"
                                                            onChange={this.handleChange}>
                                                        <option>Gas App</option>
                                                        <option>Plumb App</option>
                                                        <option>Both</option>
                                                    </select>
                                                    :
                                                    <select className="settings-field field-space" value={this.state.changedApp} name="changedApp"
                                                            onChange={this.handleChange}>
                                                        <option>{this.state.user.partner.app && this.state.user.partner.app}</option>
                                                    </select>
                                                }


                                                <span className="admintitles">Edit Title</span>
                                                <input type="text" className="settings-field field-space"  name="changedTitle" placeholder="title" value={this.state.changedTitle} onChange={this.handleChange} />
                                                <button className="button field-space" onClick={() => this.editTitle()}>Update Title</button>
                                                <span className="admintitles">Delete Post</span>
                                                <button className="button delete" onClick={() => this.deletePost()}>Delete This Item</button>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x detail-container">
                        <div className="small-12 cell">
                            <h2>Files</h2>
                            <div className="grid-x grid-padding-x">

                            {this.state.user.isAdmin === 1 &&

                            <div className="small-12 cell">
                                <div className="borderless-wrap">
                                    <div className="admin-head">
                                        <h2>Admin - Add another file</h2>
                                    </div>
                                    <div className="admin-box">
                                        <div className="file-upload field-space">
                                            <input type={"file"} className="file-upload-input" onChange={this.additionalImageChanged} key={this.state.additionalInputKey || '' } />
                                        </div>
                                        <button className="button" onClick={() => this.addAnotherImage()}>Submit Additional File</button>
                                    </div>
                                </div>
                            </div>
                            }


                            { this.state.postImages &&  this.state.postImages.map((image, c) => {

                            return <div className="cell small-12 medium-6 large-4" key={c} >
                                <div className="image-thumb">
                                    <span><span className="imghead">Date Added</span> {image.uploadDate}</span>
                                        {this.state.user.isAdmin === 1 && <button className="button deletesmall" onClick={(idx) => this.deleteImageClicked(c)}><img src={require("../../assets/img/delete.svg").default} alt="" /></button>}

                                    {(image.publicFullPath.split('.').pop() === "xlsx"|| image.publicFullPath.split('.').pop() === "csv") ?
                                    <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019–present%29.svg/512px-Microsoft_Office_Excel_%282019–present%29.svg.png?20190925171014"} onClick={(idx) => this.imageClicked(c) } alt="Post Screenshot" />
                                        :
                                        <img src={image.publicFullPath} onClick={(idx) => this.imageClicked(c) } alt="Post Screenshot" />
                                    }
                                </div>
                            </div>

                        })}
                        </div>
                        </div>

                    </div>

                </div>

            </div>)
        } else {
            body.push(<div className="main-body">

                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>{this.props.platform}</h1>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>

                    {this.state.user.isAdmin === 1 &&
                    <div className="grid-x grid-padding-x setting-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="admin-head">
                                    <h2>Admin - Change Partner</h2>
                                </div>
                                <div className="admin-box">

                                    <select className="settings-field" value={this.state.partnerID} name="partnerID"
                                            onChange={this.handlePartnerChange}>
                                        {this.state.partners}
                                    </select>
                                </div>
                            </div>

                            <div className="borderless-wrap">
                                <div className="admin-head">
                                    <h2>Admin - Add a New Item</h2>
                                </div>
                                <div className="admin-box">

                                    <span className="admintitles">App</span>


                                    {(this.state.user.partner.app && this.state.user.partner.app === DATA.BOTH) ?

                                        <select className="settings-field field-space" value={this.state.app} name="app"
                                                onChange={this.handleChange}>
                                            <option>Gas App</option>
                                            <option>Plumb App</option>
                                            <option>Both</option>
                                        </select>
                                    :
                                        <select className="settings-field field-space" value={this.state.app} name="app"
                                                onChange={this.handleChange}>
                                            <option>{this.state.user.partner.app && this.state.user.partner.app}</option>
                                        </select>
                                    }
                                    <span className="admintitles">Item Title</span>
                                    <input type="text" className="settings-field field-space" name="title" placeholder="Title" value={this.state.title} onChange={this.handleChange} />
                                    <span className="admintitles">File Upload (image, pdf or excel)</span>
                                    <div className="file-upload field-space">

                                    <input type={"file"} className="file-upload-input"  onChange={this.postImageChanged} key={this.state.postInputKey || '' } />
                                    </div>
                                    <button className="button" onClick={() => this.addPost()}>Submit Post</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }



                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Stats during this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.posts}</div>
                                        <div className="label">Items</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x table-container">
                        <div className="small-12 cell ">
                            <h2>Uploads</h2>
                        </div>
                        <div className="small-6 cell table-total">
                            <span>{this.state.postsData ? this.state.postsData.length : "-" } total uploads</span>
                        </div>
                        <div className="small-6 cell">
                        </div>

                            <div className="small-6 cell">
                                <input type="text" className="search" placeholder={"Search"}  name="search" value={this.state.search} onChange={this.handleSearchTextChange} />
                            </div>

                            <div className="small-12 cell table">
                                <table>
                                    <thead>
                                    <th>Item</th>
                                    <th>Date / Time</th>
                                    <th>Files</th>
                                    </thead>
                                    <tbody>
                                    {this.state.bigPostsTable === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.bigPostsTable.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </tbody>
                                </table>
                                <div className="small-12 cell table-nav">

                                    <TablePagination
                                        labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component={Paper}
                                        count={this.state.postsData === undefined ? 0 : this.state.postsData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={(event, newPage) => {

                                            this.setState({page: newPage})
                                        }}
                                        onChangeRowsPerPage={(event) => {
                                            this.setState({page: 0, rowsPerPage: parseInt(event.target.value, 10)})
                                        }}
                                    />
                                </div>
                            </div>


                    </div>

                </div>

            </div>)
        }




        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={this.props.platform}/>
                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>
        )
    }
}

export default SocialScreen