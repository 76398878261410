import React from 'react'
import NavBar from "../common/NavBar";
import {DATA, SECTIONS} from "../common/Strings";
import {Link} from "react-router-dom";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import Copyright from "../common/Copyright";



class AccountInfoScreen extends PartnerComponent
{

    constructor(props, context) {
        super(props, context);

        var user = undefined
        var userStr = localStorage.getItem("ppuser");
        if (user !== null) {
            user = JSON.parse(userStr);
        }
        this.state = {
            user: user,
            partners : [],
        };
        this.handlePartnerChange = this.handlePartnerChange.bind(this);
    }



    handlePartnerChange(event) {

        const target = event.target
        const value = target.value


        var formData = new FormData();
        formData.append("partnerID", value);

        Axios.post(ENDPOINTS.settings.adminChangePartner, formData)
            .then((response) => {
                if (response.data.success) {

                    let user = response.data.user

                    localStorage.setItem("ppuser", JSON.stringify(user));
                    if (user.partner.app === DATA.PLUMBAPP) {
                        localStorage.setItem("ppdata", DATA.PLUMBAPP);
                    } else {
                        if (user.partner.app === DATA.GASAPP) {
                            localStorage.setItem("ppdata", DATA.GASAPP);
                        } else {
                            var data = localStorage.getItem("ppdata");
                            if (!data){
                                localStorage.setItem("ppdata", DATA.GASAPP);
                            }

                        }

                    }
                    this.setState({
                        partnerID: value,
                        user: user,
                    }, () => this.getAccountInfo());

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not update partner info at this time. Please try again later. [104]")
            });




    }



    componentDidMount() {
        this.getAccountInfo()
        this.getPartners()
    }




    getAccountInfo() {


            var formData = new FormData();
            formData.append("partnerID", this.state.user.partnerId);

            Axios.post(ENDPOINTS.settings.getPartnerDetails, formData)
                .then((response) => {
                    if (response.data.success) {

                        let addr1 = response.data.partner.address1 ? response.data.partner.address1 : ""
                        let addr2 = response.data.partner.address2 ? response.data.partner.address2 : ""
                        let town = response.data.partner.town ? response.data.partner.town : ""
                        let county = response.data.partner.county ? response.data.partner.county : ""
                        let postcode = response.data.partner.postcode ? response.data.partner.postcode : ""
                        let tel = response.data.partner.telephone ? response.data.partner.telephone : ""
                        this.setState({
                            partnerInfo: response.data.partner,
                            address1 : addr1,
                            address2 : addr2,
                            town : town,
                            county : county,
                            postcode : postcode,
                            telephone : tel
                        })


                    } else {
                        console.log(response.data.error);
                        this.showModal("Error", response.data.error);
                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);

                    this.showModal("Error", "Could not fetch partner info at this time. Please try again later. [104]")
                });

    }


    onSaveClicked()
    {
        if (this.state.saving) {
            return
        }

        this.setState({saving : true})

        var formData = new FormData();
        formData.append("partnerID", this.state.user.partnerId);
        formData.append("address1", this.state.address1);
        formData.append("address2", this.state.address2);
        formData.append("town", this.state.town);
        formData.append("county", this.state.county);
        formData.append("postcode", this.state.postcode);
        formData.append("telephone", this.state.telephone);

        Axios.post(ENDPOINTS.settings.savePartnerDetails, formData)
            .then((response) => {
                this.setState({saving : false})

                if (response.data.success) {
                    this.showModal("Saved", "Your changes have been saved.")

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save partner info at this time. Please try again later. [104]")
            });

    }

    getPartners() {
        var formData = new FormData();

        Axios.post(ENDPOINTS.settings.getPartners, formData)
            .then((response) => {
                if (response.data.success) {

                    let partners = response.data.partners.map((row, c) => {

                        return <option key={c} value={row.id}>{row.name}</option>

                    })

                    this.setState({
                        partners: partners,
                        partnerID : this.state.user.partnerId
                    })
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch users at this time. Please try again later. [101]")
            });
    }


    render() {

        return(
        <>
            <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.SETTINGS} />


            <div className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="large-12 cell">
                            <h1>Account Settings</h1>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x settings-menu-container">
                        <div className="large-12 cell">
                            <button className="btn selected">Company Details</button> <Link to={"/settings/security"}><button className="btn">Security</button></Link> {this.props.user.isAdmin === 1 && <Link to={"/settings/users"}><button className="btn">Users</button></Link>} {this.props.user.isAdmin === 1 && <Link to={"/settings/partners"}><button className="btn">Partners</button></Link>}
                        </div>
                    </div>


                    {this.props.user.isAdmin === 1 &&
                    <div className="grid-x grid-padding-x setting-container">
                        <div className="large-12 cell">
                            <h2>Change Partner</h2>

                            <select className="settings-field" value={this.state.partnerID} name="partnerID"
                                    onChange={this.handlePartnerChange}>
                                {this.state.partners}
                            </select>
                        </div>
                    </div>
                    }

                    <div className="grid-x grid-padding-x setting-container">
                        <div className="large-12 cell">

                            <div className="settings-help">If you need any help, get in touch - <a href={"mailto:support@gasapp.co.uk"}>support@gasapp.co.uk</a></div>
                        </div>

                    </div>


                </div>

            </div>


            <div className="footer">
                <Copyright />
            </div>
            <AlertModal
                open={ (this.state.modal != null) ? this.state.modal.open : false }
                title={ (this.state.modal != null) ? this.state.modal.title : "" }
                content={ (this.state.modal != null) ? this.state.modal.content : [] }
                buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                dismissHandler={ () => this.modalWasDismissed() }
            />
        </>)



    }
}

export default AccountInfoScreen