import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import AlertModal from "../common/AlertModal";
import {Bar} from "react-chartjs-2";
import {Button} from "react-foundation";
import PartnerDateSelector from "../common/PartnerDateSelector";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Copyright from "../common/Copyright";

class ActiveUsersScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            activeLastMins : 30,
            overDays : 30,
            activeUsers: "-",
            barChartData: [],
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            data
        };

        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handleChartClick = this.handleChartClick.bind(this);
    }


    dateOrDaysChanged() {
        this.getAllUserData()
    }

    handleChartClick(event, chartBar) {

        if (chartBar[0]){
            if (chartBar[0].index && chartBar[0].index >= 1 && chartBar[0].index < this.state.barChartData.labels.length) {
                this.setState({dayIndex : chartBar[0].index, dayChartData : undefined}, () => this.getDayData(chartBar[0].index))
            }
        }
    }

    handleActiveChange(event) {

        const target = event.target
        const value = target.value

        this.setState({
            activeLastMins: value
        }, () => this.getActiveUsers());
    }


    getAllUserData() {

        this.getActiveUsers()
        this.getUsersOverTime()

    }

    getDayData(dayIdx){
        var formData = new FormData();
        formData.append("day", this.state.barChartData.labels[dayIdx]);
        formData.append("data", this.state.data);

        Axios.post(ENDPOINTS.activeUsers.getUsersOverOneDay, formData)
            .then((response) => {
                if (response.data.success) {

                    const data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                label: '# of Active users',
                                data: response.data.values,
                                backgroundColor: this.state.gradient,
                                borderWidth: 1,
                            },
                        ],
                    };

                    this.setState({
                        dayChartData: data
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    getUsersOverTime(){
        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }

        Axios.post(ENDPOINTS.activeUsers.getUsersOverTime, formData)
            .then((response) => {
                if (response.data.success) {

                    const data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                label: '# of Active users',
                                data: response.data.values,
                                backgroundColor: this.state.gradient,
                                borderWidth: 1,
                            },
                        ],
                    };

                    this.setState({
                        barChartData: data
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    getActiveUsers(){
        var formData = new FormData();
        formData.append("mins", this.state.activeLastMins);
        formData.append("data", this.state.data);

        Axios.post(ENDPOINTS.activeUsers.getActiveUserCount, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        activeUsers: response.data.count
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }

    clearDay() {
        this.setState({dayIndex: undefined})
    }

    changeDay(change) {
        var newDay = this.state.dayIndex + change
        if (newDay < 0) {
            newDay = this.state.barChartData.labels.length -1
        }
        if (newDay >= this.state.barChartData.labels.length) {
            newDay = 0
        }

        this.setState({dayIndex : newDay, dayChartData : undefined}, () => this.getDayData(newDay))
    }

    componentDidMount() {

        var canvas = document.getElementById("barchart");
        var ctx = canvas.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 0, 322);
        gradient.addColorStop(0, 'rgba(98,209,25,1)');
        gradient.addColorStop(1, 'rgba(15,119,44,1)');

        this.setState({
            gradient : gradient
        }, () => this.getAllUserData())

    }



    exportData() {

        let report = REPORTS.ACTIVEUSERS
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }

    render() {

        var body = [];
        if (this.state.dayIndex !== undefined) {

            body.push(<div className="main-body">
                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearDay() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>
                            <h1>Active Users</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changeDay(-1) }></span>
                                <h2>{this.state.barChartData.labels[this.state.dayIndex]}</h2>
                                <span className="next" onClick={ () => this.changeDay(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">


                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Active Users</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.barChartData.datasets[0].data[this.state.dayIndex]}</div>
                                        <div className="label">Users</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x detail-container">
                        <div className="small-12 cell">
                            <h2>Active users over time</h2>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">

                        <Bar id={"barchart"}
                             data={this.state.dayChartData}
                             height={333}
                             options={
                                 {
                                     plugins: {
                                         legend: {
                                             display: false,
                                         },
                                     },
                                     maintainAspectRatio: false,
                                     scales: {
                                         xAxes: [{
                                             stacked: false
                                         }],
                                         yAxes: [{
                                             stacked: false
                                         }]
                                     }
                                 }
                             }
                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)

        } else {
            body.push(<div id="divToPrint" className="main-body">
                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Active Users</h1>
                        </div>
                        <div className="small-3 cell">
                          <button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt=""/>Export Data</button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <h2>Recent Active Users</h2>
                            <div className="quick-stat-wrap">
                                <div className="box-head">
                                    <span>Show the number of active users in the last:</span>
                                    <select  className="short-select" value={this.state.activeLastMins} name="activeLastMins" onChange={this.handleActiveChange}>
                                        <option value={30}>30 minutes</option>
                                        <option value={60}>60 minutes</option>
                                        <option  value={180}>3 hours</option>
                                        <option  value={360}>6 hours</option>
                                        <option  value={720}>12 hours</option>
                                    </select>
                                </div>
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="stat">{this.state.activeUsers.toLocaleString()}</div>
                                    <div className="label">Active Users</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <h2>Active Users Over Time</h2>
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Bar id={"barchart"}
                             data={this.state.barChartData}
                             height={333}
                             options={
                                 {
                                     plugins: {
                                         legend: {
                                             display: false,
                                         },
                                     },
                                     maintainAspectRatio: false,
                                     scales: {
                                         xAxes: [{
                                             stacked: false
                                         }],
                                         yAxes: [{
                                             stacked: false
                                         }]
                                     },
                                     onClick: (elem, se) => this.handleChartClick(elem,se)
                                 }
                             }
                        />
                    </div>
                </div>
            </div>)
        }


        return(
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.ACTIVEUSERS}/>

                {body}

                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>
        )
    }
}

export default ActiveUsersScreen