import axios from 'axios'

//const BASE_URL = process.env.REACT_APP_BASE_URL;
//const BASE_URL = "http://gasappdev.app-cms.com/";
const BASE_URL = "https://gasapp.app-cms.com/";

// Set the base url for all AXIOS API calls
axios.defaults.baseURL = BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["authToken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["authToken"];
            }
        },
        onAuthFailed : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback()
                    } else {
                        return status;
                    }
                }
            }
        },
        parseResponse(resp) {
            var out = {
                success : false,
                data : null,
                error : "Unknown error occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        }
    }
})(axios);


export const ENDPOINTS = {
    login: {
        submit: "partner-portal/login",
        validatePasswordReset: "partner-portal/login/validatePasswordReset",
        requestPasswordReset: "partner-portal/login/requestUserPasswordReset",
        submitPasswordReset: "partner-portal/login/submitUserPasswordReset"
    },
    activeUsers: {
        getActiveUserCount: "partner-portal/activeUsers/getActiveUserCount",
        getUsersOverTime : "partner-portal/activeUsers/getUsersOverTime",
        getUsersOverOneDay : "partner-portal/activeUsers/getUsersOverOneDay"
    },
    breakTime: {
        getBreaktimeQuickStats : "partner-portal/breakTime/getBreaktimeQuickStats",
        getBreaktimePosts: "partner-portal/breakTime/getBreaktimePosts",
        getPostsOverTime:  "partner-portal/breakTime/getPostsOverTime",
        getBreaktimeComments: "partner-portal/breakTime/getBreaktimeComments"
    },
    merchant: {
        getMerchantQuickStats: "partner-portal/merchant/getMerchantQuickStats",
        getViewsOverTime : "partner-portal/merchant/getViewsOverTime",
        getMerchantDeals: "partner-portal/merchant/getDeals",
        getFullDealDetails : "partner-portal/merchant/getFullDealDetails",
        getSingleDealOverTime : "partner-portal/merchant/getSingleDealOverTime"
    },
    massive :{
        getQuickStats: "partner-portal/massive/getQuickStats",
        getBarChartData : "partner-portal/massive/getViewsOverTime",
        getDealsData : "partner-portal/massive/getDealsData"
    },
    liveLounge: {
        getQuickStats: "partner-portal/liveLounge/getQuickStats",
        getVideosData: "partner-portal/liveLounge/getVideosData",
        getBarChartData: "partner-portal/liveLounge/getBarChartData",
    },
    manuals: {
        getQuickStats : "partner-portal/manuals/getQuickStats",
        getBrandsData : "partner-portal/manuals/getBrandsData",
        getBarChartData : "partner-portal/manuals/getViewsOverTime",
        getManualsData : "partner-portal/manuals/getManualsData",
    },
    competitions: {
        getQuickStats : "partner-portal/competitions/getQuickStats",
        getCompetitionsData : "partner-portal/competitions/getCompetitionsData",
        getBarChartData : "partner-portal/competitions/getEntriesOverTime",
        getCompetitionDetails : "partner-portal/competitions/getCompetitionDetails",
        getCompetitionChartData : "partner-portal/competitions/getCompetitionChartData"
    },
    newsletter: {
        getQuickStats : "partner-portal/newsLetter/getQuickStats",
        getMailshotData : "partner-portal/newsLetter/getMailshotData"
    },
    settings: {
        getUsers : "partner-portal/settings/getUsers",
        getPartnerDetails : "partner-portal/settings/getPartnerDetails",
        savePartnerDetails : "partner-portal/settings/savePartnerDetails",
        saveUserEmail : "partner-portal/settings/saveUserEmail",
        changeUserPassword :  "partner-portal/settings/changeUserPassword",
        getPartners :  "partner-portal/settings/getPartners",
        saveUserDetails : "partner-portal/settings/saveUserDetails",
        deleteUser : "partner-portal/settings/deleteUser",
        deletePartner : "partner-portal/settings/deletePartner",
        adminChangePartner : "partner-portal/settings/changePartner",
        uploadLogo : "partner-portal/settings/uploadLogo",
    },
    export: {
        getCSV: "partner-portal/export/getCSV",
    },
    social:  {
        getQuickStats : "partner-portal/socials/getQuickStats",
        getPostsData : "partner-portal/socials/getPostsData",
        getBarChartData :  "partner-portal/socials/getStatsOverTime",
        getPostsImages : "partner-portal/socials/getPostsImages",
        uploadImage : "partner-portal/socials/uploadImage",
        deleteImage : "partner-portal/socials/deleteImage",
        editPost : "partner-portal/socials/editPost",
        deletePost: "partner-portal/socials/deletePost",
    },
    notes:  {
        getPostsData : "partner-portal/notes/getPostsData",
        uploadPost : "partner-portal/notes/uploadPost",
        editPost : "partner-portal/notes/editPost",
        deletePost: "partner-portal/notes/deletePost",
    }
}