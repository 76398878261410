import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS, DATA, PIE_COLOURS, VIEW} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import {Button} from "react-foundation";
import {Bar, Pie} from "react-chartjs-2";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper/Paper";
import Copyright from "../common/Copyright";

class MassiveScreen extends PartnerComponent {


    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            views: "-",
            barChartData: [],
            viewType: VIEW.DETAIL,
            data,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    }

    getAllMassiveData() {
        this.getMassiveQuickStats()
        this.getTableData()
        this.getBarChartData()
    }


    exportData() {

        let report = REPORTS.MASSIVE
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }


    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateDealsElems());

    }


    getTableData(){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.massive.getDealsData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        dealData: response.data.deals,
                    }, () => this.updateDealsElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }


    setViewType(type) {

        this.setState({viewType: type})
    }

    updateDealsElems() {

        var matching = []
        if (this.state.search)
        {
            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.dealData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.dealData[c].title.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.dealData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.dealData
        }
        var pcIDX = 0
        var pieBGs = []
        var pieLabels = []
        var pieData = []
        var totalViews = 0

        var dealsTble = matching.map((row, c) => {

            totalViews = totalViews + parseInt(row.views)

            let app = row.db === "plumbapp" ? "[PA]" : "[GA]"

            return <tr  >
                <td>{row.title} {app}</td>
                <td>{row.dateAdded}</td>
                <td>{row.views.toLocaleString()}</td>
                <td><a href={row.url}>Click here to view deal.</a></td>
            </tr>

        })

        this.setState({dealsTableData : dealsTble,  matchingDeals : matching })
    }

    dealClicked(dealIDX){
        this.setState({selectedDeal : dealIDX}, () => this.getFullDealDetails())
    }

    changeDeal(change) {
        var newDeal = this.state.selectedDeal + change
        if (newDeal < 0) {
            newDeal = this.state.matchingDeals.length -1
        }
        if (newDeal >= this.state.matchingDeals.length) {
            newDeal = 0
        }

        this.setState({selectedDeal : newDeal, dealtails: undefined}, () => this.getFullDealDetails())
    }

    clearDeal() {

        this.setState({selectedDeal: undefined, dealtails: undefined})
    }


    getBarChartData(dealID){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        if (dealID !== undefined) {
            formData.append("dealID", dealID);
            var source = DATA.GASAPP
            if (this.state.brandsData[this.state.selectedBrand].db.includes("plumb")) {
                source = DATA.PLUMBAPP
            }
            formData.append("source", source);

        }
        Axios.post(ENDPOINTS.massive.getBarChartData, formData)
            .then((response) => {
                if (response.data.success) {

                    const data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                label: 'Views',
                                data: response.data.values,
                                backgroundColor: this.state.gradients[this.state.gradients.length-1],
                                borderWidth: 1,
                            },
                        ],
                    };

                    if (dealID !== undefined) {
                        this.setState({
                            dealBarChartData: data
                        });
                    } else {
                        this.setState({
                            barChartData: data
                        });
                    }

                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    getFullDealDetails() {

        this.getSingleDealChart()
    }

    getSingleDealChart(){

        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        formData.append("dealID", this.state.dealData[this.state.selectedDeal].id);
        var source = DATA.GASAPP
        if ( this.state.dealData[this.state.selectedDeal].db.includes("plumb")) {
            source = DATA.PLUMBAPP
        }
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        formData.append("source", source);
        Axios.post(ENDPOINTS.massive.getBarChartData, formData)
            .then((response) => {
                if (response.data.success) {

                    const data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                label: 'Views',
                                data: response.data.values,
                                backgroundColor: this.state.gradients[this.state.gradients.length-1],
                                borderWidth: 1,
                            },
                        ],
                    };

                    this.setState({
                        singleChartData: data
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }



    getMassiveQuickStats(){
         var formData = new FormData();

         formData.append("overDays", this.state.overDays);
         formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
         Axios.post(ENDPOINTS.massive.getQuickStats, formData)
             .then((response) => {
                 if (response.data.success) {

                     this.setState({
                         views: response.data.views,
                     });
                 } else {
                     console.log(response.data.error);

                 }
             })
             .catch(error => {
                 console.log("ERROR");
                 console.log(error);
             });



    }



    dateOrDaysChanged() {
        this.getAllMassiveData()
    }

    componentDidMount() {
        this.dateOrDaysChanged()
    }


    render() {

        var body = [];

        var tableSection = []

        if (this.state.selectedDeal !== undefined) {
            body.push(<div className="main-body">
                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearDeal() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>
                            <h1>The Vault</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changeDeal(-1) }></span>
                                <h2>{this.state.matchingDeals[this.state.selectedDeal].title}</h2>
                                <span className="next" onClick={ () => this.changeDeal(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">


                        </div>
                    </div>


                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Stats</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.matchingDeals[this.state.selectedDeal].views.toLocaleString()}</div>
                                        <div className="label">Views</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>)

        } else {

            if (this.state.viewType === VIEW.PIE) {
                tableSection.push(<div className="grid-x grid-padding-x table-container">
                    <div className="small-12 cell ">
                        <h2>The Vault</h2>
                    </div>
                    <div className="small-6 cell table-total">
                        <span>{this.state.dealData && this.state.dealData.length} Total deals during this period</span>
                    </div>
                    <div className="small-6 cell">

                    </div>
                    <div className="large-6 small-12 cell table">
                        <table>
                            <thead>
                            <th>Deal Title</th>
                            <th>No of views</th>
                            <th>Percentage</th>
                            </thead>
                            <tbody>
                            {this.state.smallDealsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.smallDealsTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}

                            </tbody>
                        </table>

                        <div className="grid-x grid-padding-x">


                            <div className="small-12 cell table-nav">

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    count={this.state.smallDealsTableData === undefined ? 0 : this.state.smallDealsTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>

                        </div>


                    </div>
                    <div className="cell large-6 small-12 pie-chart">
                        <Pie data={this.state.pieChartData} options={
                            {
                                plugins: {
                                    legend: {
                                        labels: {
                                            padding: 13
                                        },
                                        display: this.state.pieChartData ? (this.state.pieChartData.labels.length <= 15) : false,
                                        position: 'bottom',
                                        align: 'start'
                                    }
                                },


                            }
                        } />
                    </div>

                </div>)
            } else {
                tableSection.push(<div className="grid-x grid-padding-x table-container">
                    <div className="small-12 cell ">
                        <h2>The Vault</h2>
                    </div>
                    <div className="small-6 cell table-total">
                        <span>{this.state.dealData && this.state.dealData.length} Total deals during this period</span>
                    </div>
                    <div className="small-6 cell">
                        <input type="text" placeholder="Search" className="search" onChange={this.handleSearchTextChange}  name="search" value={this.state.search}  />
                    </div>

                    <div className="small-12 cell table">
                        <table>
                            <thead>
                            <th>Deal Title</th>
                            <th>Date Added</th>
                            <th>Views</th>
                            <th>Link</th>
                            </thead>
                            <tbody>
                            {this.state.dealsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.dealsTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}

                            </tbody>
                        </table>
                        <div className="grid-x grid-padding-x">


                            <div className="small-12 cell table-nav">

                                <TablePagination
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.smallDealsTableData === undefined ? 0 : this.state.smallDealsTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>

                        </div>
                    </div>

                </div>)
            }


            body.push(<div id="divToPrint" className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>The Vault</h1>
                        </div>
                        <div className="small-3">
                            <Button className="button export-btn"  onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt="" />Export Data
                            </Button>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total views in this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.views.toLocaleString()}</div>
                                        <div className="label">Views</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {tableSection}




                </div>

            </div>)
        }



        return (

            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.MASSIVE}/>

                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>

        )
    }
}

export default MassiveScreen
