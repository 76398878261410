import React from 'react'
import {SECTIONS, REPORTS, DATA} from "./Strings";
import {Link} from "react-router-dom";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import PartnerInfo from "./PartnerInfo";

class NavBar extends React.Component {

    constructor(props, context) {
        super(props, context)

        var user = undefined
        var userStr = localStorage.getItem("ppuser");
        var data = localStorage.getItem("ppdata");
        if (user !== null) {
            user = JSON.parse(userStr);
        }
        this.state = {
            showingMenu : false,
            user: user,
            mobMenuOpen : false,
            data: data
        };
    }

    componentDidMount() {

    }

    displayAccountModal() {

        this.props.showModal(this.state.partnerInfo.name, <PartnerInfo partner={this.state.partnerInfo} />, []);

    }


    showAccountInfo() {

        if (this.state.partnerInfo) {

            this.displayAccountModal()

        } else {
            var formData = new FormData();
            formData.append("partnerID", this.state.user.partnerId);

            Axios.post(ENDPOINTS.settings.getPartnerDetails, formData)
                .then((response) => {
                    if (response.data.success) {

                        this.setState({
                           partnerInfo: response.data.partner
                        }, () => this.displayAccountModal())


                    } else {
                        console.log(response.data.error);
                        this.props.showModal("Error", response.data.error);
                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);

                    this.props.showModal("Error", "Could not fetch partner info at this time. Please try again later. [103]")
                });

        }
    }

    showHideMenu() {
        this.setState({
            showingMenu : !this.state.showingMenu
        })
    }

    didSelectDataSource(source){
        this.setState({
            showingMenu : false,
            mobMenuOpen : false,
            data : source
        })
        localStorage.setItem("ppdata", source);

        if (this.props.onDataSourceChanged) {
            this.props.onDataSourceChanged(source)
        }
    }
    hideShowMobMenu(state){
        this.setState({mobMenuOpen : state})
    }

    render() {

        var selectorClass = "selected-module ";
        var selectorImg = <img src={require("../../assets/img/mini-gasapp-logo.svg").default} alt="" />
        var gasappImg = <img src={require("../../assets/img/mini-gasapp-logo.svg").default} alt="" />
        var plumbappImg = <img src={require("../../assets/img/mini-plumbapp-logo.svg").default} alt="" />
        var bothImg = <img src={require("../../assets/img/mini-both-logo.svg").default} alt="" />
        if (this.state.data) {
            switch (this.state.data) {
           case DATA.GASAPP :
               selectorClass = "selected-module sm_gasapp"
               selectorImg = <img src={require("../../assets/img/mini-gasapp-logo.svg").default} alt="" />
               break
           case DATA.PLUMBAPP :
               selectorClass = "selected-module sm_plumbapp"
               selectorImg = <img src={require("../../assets/img/mini-plumbapp-logo.svg").default} alt="" />
               break
           case DATA.BOTH :
               selectorClass = "selected-module sm_both"
               selectorImg = <img src={require("../../assets/img/mini-both-logo.svg").default} alt="" />
               break

                default:
                    console.log("unknown data state!!")

            }
        }
        var shouldShowMenu = false
        if (this.state.user.partner.app && this.state.user.partner.app === DATA.BOTH) {
            shouldShowMenu = true
        }

        var innerMobMenuStyle = this.state.mobMenuOpen ? "block" :  "none"

        return (<div>

            <div className="mob-menu">
                <div className="mob-bar">
                    <div className="nav-logo">
                    <img src={require("../../assets/img/gas_app_partners_hori_icon.svg").default} alt="" />
                    </div>
                    <div className="hamburger" onClick={() => this.hideShowMobMenu(!this.state.mobMenuOpen)}>
                        <img src={require("../../assets/img/hamburger.svg").default} alt="" />
                    </div>
                </div>
                <div className="inner-mob-menu" style={{display : innerMobMenuStyle}}>
                    <div className="menlist">

                        <a onClick={() => this.showHideMenu()} className={selectorClass}>{selectorImg}{shouldShowMenu && <span className={"arrow"}></span>}</a>
                        {this.state.showingMenu && shouldShowMenu &&  <ul className="menu vertical">
                            <li><a onClick={() => this.didSelectDataSource(DATA.GASAPP)}>{gasappImg}</a></li>
                            <li><a href="#" onClick={() => this.didSelectDataSource(DATA.PLUMBAPP)}>{plumbappImg}</a></li>
                            <li><a href="#" onClick={() => this.didSelectDataSource(DATA.BOTH)}>{bothImg}</a></li>
                        </ul>}

                    </div>
                    <div className="menlist">
                        <Link onClick={()=>this.hideShowMobMenu(false)} to={"/"} className={this.props.section === SECTIONS.REPORTS ? "button selected" : "button"}>Reports</Link>
                    </div>
                    <div className="menlist">
                        <Link onClick={()=>this.hideShowMobMenu(false)} to={"/settings"} className={this.props.section === SECTIONS.SETTINGS ? "button selected" : "button"} href="#">Account Settings</Link>
                    </div>
                    <div className="menlist">
                        <div onClick={this.props.onUserLogout}><a className="button" href="#">Logout<img src={require("../../assets/img/logout.svg").default} alt="" /></a></div>
                    </div>
                </div>

            </div>

            <div className="top-bar">
                <div className="top-bar-left">

                    <ul className="dropdown menu" data-dropdown-menu>
                        <li><img className="nav-logo" src={require("../../assets/img/gas_app_partners_hori_icon.svg").default} alt="" /></li>
                        <li>
                            <a onClick={() => this.showHideMenu()} className={selectorClass}>{selectorImg}{shouldShowMenu && <span className={"arrow"}></span>}</a>
                            {this.state.showingMenu && shouldShowMenu &&  <ul className="menu vertical">
                                <li><a onClick={() => this.didSelectDataSource(DATA.GASAPP)}>{gasappImg}</a></li>
                                <li><a href="#" onClick={() => this.didSelectDataSource(DATA.PLUMBAPP)}>{plumbappImg}</a></li>
                                <li><a href="#" onClick={() => this.didSelectDataSource(DATA.BOTH)}>{bothImg}</a></li>
                            </ul>}
                        </li>
                    </ul>
                </div>

                <div className="top-bar-right">
                    <ul className="menu">
                        <li><Link to={"/"} className={this.props.section === SECTIONS.REPORTS ? "button selected" : "button"}>Reports</Link></li>
                        <li><Link to={"/settings"} className={this.props.section === SECTIONS.SETTINGS ? "button selected" : "button"} href="#">Account Settings</Link></li>
                        <li onClick={this.props.onUserLogout}><a className="button" href="#"><img src={require("../../assets/img/logout.svg").default} alt="" /></a></li>
                    </ul>
                </div>
            </div>


                {this.props.section === SECTIONS.REPORTS &&

                <div className="secmenu">
                    <div className="secmenu-left">
                        <div className="partner-logo">

                        </div>

                    </div>
                    <div className="secmenu-right">
                        <div className="sponsor-details">
                            <h1 className="sponsor-name">{this.state.user.partner.name}</h1>

                        </div>
                        <div className="nav-selection">
                            <div className="button-array">
                                <Link to={"/"} className={this.props.report === REPORTS.ACTIVEUSERS ? "button selected" : "button"} >{REPORTS.ACTIVEUSERS}</Link>
                                <Link to={"/reports/merchant"} className={this.props.report === REPORTS.MERCHANT ? "button selected" : "button"} >{REPORTS.MERCHANT}</Link>
                                <Link to={"/reports/massive"} className={this.props.report === REPORTS.MASSIVE ? "button selected" : "button"} >{REPORTS.MASSIVE}</Link>
                                <Link to={"/reports/manuals"} className={this.props.report === REPORTS.MANUALS ? "button selected" : "button"} >{REPORTS.MANUALS}</Link>
                                <Link to={"/reports/competitions"} className={this.props.report === REPORTS.COMPETITIONS ? "button selected" : "button"} >{REPORTS.COMPETITIONS}</Link>
                                <Link to={"/reports/livelounge"} className={this.props.report === REPORTS.LIVELOUNGE ? "button selected" : "button"} >{REPORTS.LIVELOUNGE}</Link>
                                <Link to={"/reports/misc"} className={this.props.report === REPORTS.TIKTOK ? "button selected" : "button"} >{REPORTS.TIKTOK}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )

    }

}

export default NavBar