import React from "react";
import {PIE_COLOURS} from "./common/Strings";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

//Put usefull stuff for many screens : here
class PartnerComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.handleOverDaysChange = this.handleOverDaysChange.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
    }

    askWhichFile() {
        let buttons = [
            {
                label : "Spreadsheet",
                style : "btn-default",
                click : () => {
                    this.exportData();
                    this.hideModal();
                }
            },
            {
                label : " PDF ",
                style : "btn-default",
                click : () => {
                    this.hideModal();
                    this.printDocument()
                }
            }
        ];
        this.showModal("Export Data", "Please select an option.", buttons)
    }


    printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                });
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save("download.pdf");
            })
        ;
    }

    onDataSourceChanged(newSource) {
        this.setState({
            data: newSource
        }, () => this.dateOrDaysChanged());
    }

    handleFromDateChange(date) {

        this.setState({
            fromDate: date
        }, () => this.dateOrDaysChanged());
    }
    handleToDateChange(date) {

        this.setState({
            toDate: date
        }, () => this.dateOrDaysChanged());
    }


    dateOrDaysChanged() {
        //
    }

    setUpGradients(elemName) {
        var canvas = document.getElementById(elemName);
        var ctx = canvas.getContext("2d");
        var gradients = [];
        for (var c=0; c < PIE_COLOURS.length; c++) {
            var gradient = ctx.createLinearGradient(0, 0, 0, 322);
            gradient.addColorStop(0, PIE_COLOURS[c].start);
            gradient.addColorStop(1, PIE_COLOURS[c].end);
            gradients.push(gradient)
        }

        this.setState({
            gradients : gradients
        }, () => this.dateOrDaysChanged())
    }

    handleOverDaysChange(event) {

        const target = event.target
        const value = target.value
        this.setState({
            overDays: value
        }, () => this.dateOrDaysChanged());
    }

    getDatetimeDate(date, startOfDay) {

        let month = '0' +(date.getMonth()+1)
        let day = '0' + date.getDate()
        var time =" 00:00:01";
        if (!startOfDay) {
            time =" 23:59:59";
        }
        return date.getFullYear() + "-" + month.slice(-2) + "-"  + day.slice(-2) + time
    }


    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     */
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log("name "+ name+ " = " + value)
        this.setState({
            [name] : value
        })
    }


    validateCreateFormData(inObj, ruleArray) {
        var outBundle = {
            success : true
        };

        var formData = new FormData();

        for (var i = 0; i < ruleArray.length; i++) {
            var rules = ruleArray[i];

            var keyName = rules.key;
            var type = rules.type;
            var label = rules.label;
            var postName = rules.postName;
            var optional = rules.optional;
            var defaultValue = rules.default;

            if (keyName === undefined) {
                console.log("Validation rule was passed with no key. Skipping.");
                continue;
            }

            if (type === undefined) {
                type = "string";
            }

            if (optional === undefined) {
                optional = false;
            }

            if (inObj.hasOwnProperty(keyName)) {
                var valid = true;
                var invalidReason = "";

                var value = inObj[keyName];
                if (value === undefined && defaultValue !== undefined) {
                    value = defaultValue;
                }

                if (type !== undefined) {
                    if (type === "int" || type === "integer" || type === "float" || type === "double") {
                        if (type === "int" || type === "integer") {
                            value = parseInt(value);
                        } else if (type === "float" || type === "double") {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            valid = false;
                            invalidReason = "Please provide a valid number for " + label;
                        }

                        if (valid && rules.hasOwnProperty("greaterZero")) {
                            if (rules.greaterZero) {
                                if (value <= 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater than zero for " + label;
                                }
                            }
                        }

                        if (valid && rules.hasOwnProperty("greaterEqualsZero")) {
                            if (rules.greaterEqualsZero) {
                                if (value < 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater or equal to zero for " + label;
                                }
                            }
                        }
                    } else if (type === "string") {
                        var notEmpty = true;
                        if (rules.hasOwnProperty("notEmpty")) {
                            notEmpty = rules.notEmpty
                        }

                        if (notEmpty) {
                            if (value === "") {
                                valid = false;
                                invalidReason = "Please provide a " + label;
                            }
                        }
                    }
                }

                if (valid) {
                    if (postName !== undefined) {
                        formData.append(postName, value);
                    }
                } else if (!optional) {
                    outBundle.success = false;
                    outBundle.error = invalidReason;
                    break;
                }
            } else if (!optional) {
                if (defaultValue !== undefined && postName !== undefined) {
                    formData.append(postName, defaultValue);
                } else {
                    outBundle.success = false;
                    outBundle.error = "Please provide a " + label;
                    break;
                }
            }
        }

        if (outBundle.success) {
            outBundle.formData = formData;
        }

        return outBundle;
    }

    showModal(title, content, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    style : "btn-default",
                    click : () => {
                        this.hideModal();
                    }
                }
            ];
        }

        var modal = {
            open : true,
            title : title,
            content : content,
            buttons : buttons
        };

        this.setState( {
            modal : modal
        })
    }

    hideModal() {

        this.setState( {
            modal : {
                open : false
            }
        })
    }

    modalWasDismissed() {
        this.hideModal();
    }

}

export default PartnerComponent;
