import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS, PIE_COLOURS, VIEW, DATA} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import {Button} from "react-foundation";
import {Bar, Pie} from "react-chartjs-2";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper/Paper";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Copyright from "../common/Copyright";

class CompetitionsScreen extends PartnerComponent {


    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            entries: "-",
            barChartData: [],
            view: VIEW.DETAIL,
            data,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
        };

        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);

    }

    exportData() {

        let report = REPORTS.COMPETITIONS
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }



    dateOrDaysChanged() {
        this.getAllCompsData()
    }

    getAllCompsData() {

        this.getCompQuickStats()
        this.getCompsTableData()

    }




    getCompsTableData() {
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.competitions.getCompetitionsData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        compsData: response.data.competitions,
                    }, () => this.updateCompsElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }



    compClicked(idx) {
        this.setState({
            selectedComp: idx
        }, () => this.updateCompDetails());

    }
    clearComp() {

        this.setState({selectedComp: undefined,compDetails:undefined})
    }

    changeComt(change) {
        var newComp = this.state.selectedComp + change

        if (newComp < 0) {
            newComp = this.state.compsData.length -1
        }
        if (newComp >= this.state.compsData.length) {
            newComp = 0
        }

        this.setState({selectedComp : newComp ,compDetails:undefined}, () => this.updateCompDetails())
    }


    updateCompDetails() {
        this.getCompDetails()

    }


    getCompDetails() {
        var formData = new FormData();

        formData.append("competitionID", this.state.compsData[this.state.selectedComp].id);
        var source = DATA.GASAPP
        if (this.state.compsData[this.state.selectedComp].db.includes("plumb")) {
            source = DATA.PLUMBAPP
        }
        formData.append("source", source);

        Axios.post(ENDPOINTS.competitions.getCompetitionDetails, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        compDetails: response.data.competition,
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }




    updateCompsElems() {


        var matching = []

        if (this.state.search)
        {

            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.compsData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.compsData[c].title.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.compsData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.compsData
        }



        var pcIDX = 0
        var pieBGs = []
        var pieLabels = []
        var pieData = []
        var totalEntries = 0
        for (var x=0;x<this.state.compsData.length;x++){
            totalEntries += parseInt(this.state.compsData[x].entries)
        }
        var compsTarble = this.state.compsData.map((row, c) => {
            let app = row.db === "plumbapp" ? " [PA]" : " [GA]"


            return <tr key={c} onClick={(idx) => this.compClicked(c)}>
                <td>{row.title}{app}</td>
                <td>{row.entries}</td>
                <td>{totalEntries > 0 && Math.floor(((row.entries / totalEntries) * 1000))/10 + "%"}</td>

            </tr>

        })

        var bigCompsTarble = matching.map((row, c) => {

            let app = row.db === "plumbapp" ? " [PA]" : " [GA]"


            return  <tr key={c} onClick={(idx) => this.compClicked(c)}>
                <td>{row.title}{app}</td>
                <td>{row.startDate}</td>
                <td>{row.endDate}</td>
                <td>{row.entries}</td>
                <td>{row.correct}</td>
                <td>{row.entries - row.correct}</td>
            </tr>

        })


        this.setState({compsTable : compsTarble,bigCompsTable : bigCompsTarble })
    }



    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateCompsElems());

    }


    getCompQuickStats(){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.competitions.getQuickStats, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        entries: response.data.entries
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });



    }

    setViewType(type) {

        this.setState({view: type})
    }


    componentDidMount() {
        this.dateOrDaysChanged()

    }

    render() {

        var body = [];

        if (this.state.selectedComp !== undefined) {
            body.push(<div className="main-body">

                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearComp() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>

                            <h1>Competitions</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changeComt(-1) }></span>
                                <h2>{this.state.compsData[this.state.selectedComp].title} {this.state.compsData[this.state.selectedComp].db === "plumbapp" ? " [PA]" : " [GA]"}</h2>
                                <span className="next" onClick={ () => this.changeComt(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>


                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Stats</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-3">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.compsData[this.state.selectedComp].entries.toLocaleString()}</div>
                                        <div className="label">Entries</div>
                                    </div>
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.compsData[this.state.selectedComp].correct.toLocaleString()}</div>
                                        <div className="label">Correct Answers</div>
                                    </div>
                                    <div className="whole-stat">
                                        <div className="stat">{(this.state.compsData[this.state.selectedComp].entries - this.state.compsData[this.state.selectedComp].correct).toLocaleString()}</div>
                                        <div className="label">Incorrect Answers</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x detail-container">
                        <div className="small-12 cell">
                            <h2>Competition Details</h2>
                            <div className="detail-wrap">
                                <div className="grid-x grid-padding-x">
                                    <div className="cell large-6 small-12">
                                        <h3>Title</h3>
                                        <p>{this.state.compsData[this.state.selectedComp].title}</p>
                                        <h3>Description</h3>
                                        {this.state.compDetails && this.state.compDetails.description &&
                                        <p>{this.state.compDetails.description}</p>
                                        }
                                        <h3>Prize Description</h3>
                                        {this.state.compDetails && this.state.compDetails.prizeDescription &&
                                        <p>{this.state.compDetails.prizeDescription}</p>
                                        }
                                        <h3>Question</h3>
                                        {this.state.compDetails && this.state.compDetails.question &&
                                        <p>{this.state.compDetails.question}</p>
                                        }
                                    </div>
                                    <div className="cell large-6 small-12">
                                        <h3>Prize Image</h3>
                                        <div className="image-gal">
                                            {this.state.compDetails && this.state.compDetails.image &&
                                            <img
                                                src={this.state.compDetails.image}
                                                alt=""/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>




                </div>

            </div>)
        } else {
            body.push(<div id="divToPrint"  className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Competitions</h1>
                        </div>
                        <div className="small-3">
                            <Button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt="" />Export Data
                            </Button>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total competition entries in this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.entries.toLocaleString()}</div>
                                        <div className="label">Entries</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x table-container">
                        <div className="small-12 cell ">
                            <h2>Competitions</h2>
                        </div>
                        <div className="small-6 cell table-total">
                            <span>{this.state.compsData ? this.state.compsData.length : "-" } total competitions</span>
                        </div>
                        <div className="small-6 cell">
                        </div>

                        {this.state.view === VIEW.PIE &&

                        <>
                            <div className="small-12 large-6 cell table">
                                <table>
                                    <thead>
                                    <th>Competition</th>
                                    <th>No of entries</th>
                                    <th>Percentage</th>
                                    </thead>
                                    <tbody>
                                    {this.state.compsTable}
                                    </tbody>
                                </table>
                                <div className="small-12 cell table-nav">

                                    <TablePagination
                                        labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component={Paper}
                                        count={this.state.compsData === undefined ? 0 : this.state.compsData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={(event, newPage) => {

                                            this.setState({page: newPage})
                                        }}
                                        onChangeRowsPerPage={(event) => {
                                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="cell small-12 large-6 pie-chart">
                                <Pie data={this.state.pieChartData} options={
                                    {
                                        plugins: {
                                            legend: {
                                                labels: {
                                                    padding: 13
                                                },
                                                display: this.state.pieChartData ? (this.state.pieChartData.labels.length <= 15) : false,
                                                position: 'bottom',
                                                align: 'start'
                                            }
                                        },


                                    }
                                } />
                            </div>
                        </>

                        }

                        {this.state.view === VIEW.DETAIL &&

                        <>
                            <div className="small-6 cell">
                                <input type="text" className="search" placeholder={"Search"}  name="search" value={this.state.search} onChange={this.handleSearchTextChange} />

                            </div>

                            <div className="small-12 cell table">
                                <table>
                                    <thead>
                                    <th>Competition</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>No of Entries</th>
                                    <th>Correct Answers</th>
                                    <th>Incorrect Answers</th>
                                    </thead>
                                    <tbody>

                                    {this.state.bigCompsTable}
                                    </tbody>
                                </table>
                                <div className="small-12 cell table-nav">

                                    <TablePagination
                                        labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component={Paper}
                                        count={this.state.compsData === undefined ? 0 : this.state.compsData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={(event, newPage) => {

                                            this.setState({page: newPage})
                                        }}
                                        onChangeRowsPerPage={(event) => {
                                            this.setState({page: 0, rowsPerPage: parseInt(event.target.value, 10)})
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                        }

                    </div>







                </div>

            </div>)
        }



        return (
                <>
                    <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.COMPETITIONS}/>

                    {body}
                    <div className="footer">
                        <Copyright />
                    </div>
                    <AlertModal
                        open={ (this.state.modal != null) ? this.state.modal.open : false }
                        title={ (this.state.modal != null) ? this.state.modal.title : "" }
                        content={ (this.state.modal != null) ? this.state.modal.content : [] }
                        buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                        dismissHandler={ () => this.modalWasDismissed() }
                    />
                </>
            )
    }
}

export default CompetitionsScreen