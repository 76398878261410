import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS, VIEW, PIE_COLOURS} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import Copyright from "../common/Copyright";
import {Button} from "react-foundation";
import {Bar, Pie} from "react-chartjs-2";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper/Paper";

class NewsletterScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            subscribers: "-",
            opens: "-",
            emails: "-",
            barChartData: [],
            data,
            page: 0,
            viewType: VIEW.DETAIL,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            rowsPerPage : 10,
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    }

    exportData() {

        let report = REPORTS.NEWSLETTER
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }

    setViewType(type) {

        this.setState({viewType: type})
    }

    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateEmailElems());

    }


    getAllNewsletterData() {

        this.getNewsletterQuickStats()
        this.getTableData()

    }


    getTableData(){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.newsletter.getMailshotData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        emailData: response.data.emails,
                    }, () => this.updateEmailElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    updateEmailElems() {

        var matching = []
        if (this.state.search)
        {
            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.emailData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.emailData[c].title.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.emailData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.emailData
        }
        var pcIDX = 0
        var pieBGs = []
        var pieLabels = []
        var pieData = []
        var totalViews = 0

        var emailsTable = matching.map((row, c) => {

            totalViews = totalViews + parseInt(row.views)


            return <tr key={c} onClick={(idx) => this.emailClicked(c)}>
                <td>{row.title}</td>
                <td>{row.date}</td>
                <td>{row.time}</td>
                <td>{row.emails.toLocaleString()}</td>
                <td>{row.opens.toLocaleString()}</td>
                <td>{row.clicks}</td>
            </tr>

        })
        var smallmailsTble = matching.map((row, c) => {



            return <tr key={c} onClick={(idx) => this.emailClicked(c)}>
                <td>{row.title}</td>
                <td>{row.opens.toLocaleString()}</td>
            </tr>

        })



        this.setState({emailsTableData : emailsTable, smallEmailsTableData : smallmailsTble,matchingEmails : matching})
    }



    emailClicked(idx){
        window.location.href = this.state.emailData[idx].url;

    }



    getNewsletterQuickStats(){
         var formData = new FormData();

         formData.append("overDays", this.state.overDays);
         formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
         Axios.post(ENDPOINTS.newsletter.getQuickStats, formData)
             .then((response) => {
                 if (response.data.success) {

                     this.setState({
                         subscribers: response.data.subscribers,
                         emails: response.data.emails,
                         opens: response.data.opens,
                     });
                 } else {
                     console.log(response.data.error);

                 }
             })
             .catch(error => {
                 console.log("ERROR");
                 console.log(error);
             });

    }



    dateOrDaysChanged() {
        this.getAllNewsletterData()
    }

    componentDidMount() {
        this.setUpGradients("barchart")
    }




    render() {

        var body = [];

        var tableSection = []


        if (this.state.viewType === VIEW.PIE) {
            tableSection.push(<div className="grid-x grid-padding-x table-container">
                <div className="small-12 cell ">
                    <h2>Emails</h2>
                </div>
                <div className="small-6 cell table-total">
                    <span>{this.state.emailData && this.state.emailData.length} Total Emails</span>
                </div>
                <div className="small-6 cell">

                </div>
                <div className="large-6 small-12 cell table">
                    <table>
                        <thead>
                        <th>Campaign Name</th>
                        <th>Impressions</th>
                        </thead>
                        <tbody>
                        {this.state.smallEmailsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.smallEmailsTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </tbody>
                    </table>

                    <div className="grid-x grid-padding-x">


                        <div className="small-12 cell table-nav">

                            <TablePagination
                                labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                rowsPerPageOptions={[10, 25, 100]}
                                component={Paper}
                                count={this.state.smallEmailsTableData === undefined ? 0 : this.state.smallEmailsTableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={(event, newPage) => {
                                    this.setState({page: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />
                        </div>

                    </div>


                </div>
                <div className="cell large-6 small-12 pie-chart">
                    <Pie data={this.state.pieChartData} options={
                        {
                            plugins: {
                                legend: {
                                    labels: {
                                        padding: 13
                                    },
                                    display: this.state.pieChartData ? (this.state.pieChartData.labels.length <= 15) : false,
                                    position: 'bottom',
                                    align: 'start'
                                }
                            },


                        }
                    } />
                </div>

            </div>)
        } else {
            tableSection.push(<div className="grid-x grid-padding-x table-container">
                <div className="small-12 cell ">
                    <h2>Emails</h2>
                </div>
                <div className="small-6 cell table-total">
                    <span>{this.state.emailData && this.state.emailData.length} Total Emails</span>
                </div>
                <div className="small-6 cell">
                    <input type="text" placeholder="Search" className="search" onChange={this.handleSearchTextChange}  name="search" value={this.state.search}  />
                </div>

                <div className="small-12 cell table">
                    <table>
                        <thead>
                        <th>Campaign Name</th>
                        <th>Date Sent</th>
                        <th>Time Sent</th>
                        <th>People Sent To</th>
                        <th>Impressions</th>
                        <th>Clicks</th>
                        </thead>
                        <tbody>
                        {this.state.emailsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.emailsTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}

                        </tbody>
                    </table>
                    <div className="grid-x grid-padding-x">


                        <div className="small-12 cell table-nav">

                            <TablePagination
                                labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                rowsPerPageOptions={[10, 25, 100]}
                                component={Paper}
                                count={this.state.emailsTableData === undefined ? 0 : this.state.emailsTableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={(event, newPage) => {

                                    this.setState({page: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />
                        </div>

                    </div>
                </div>

            </div>)
        }


        body.push(<div id="divToPrint"  className="main-body">


            <div className="grid-container">


                <div className="grid-x grid-padding-x header-container">
                    <div className="small-9 cell">
                        <h1>Newsletter</h1>
                    </div>
                    <div className="small-3">
                        <Button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt="" />Export Data
                        </Button>
                    </div>
                </div>


                <div className="grid-x grid-padding-x date-container">
                    <div className="small-12 cell">
                        <div className="borderless-wrap">
                            <div className="box-head">
                                <span>Date Range</span>
                                <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                     toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid-x grid-padding-x stat-container">
                    <div className="small-12 cell">
                        <h2>Email Newsletter stats</h2>
                        <div className="quick-stat-wrap">
                            <div className="stat-wrap stat-wrap-3">
                                <div className="whole-stat">
                                    <div className="stat">{this.state.subscribers.toLocaleString()}</div>
                                    <div className="label">Subscribers</div>
                                </div>
                                <div className="whole-stat">
                                    <div className="stat">{this.state.emails.toLocaleString()}</div>
                                    <div className="label">Emails Sent</div>
                                </div>
                                <div className="whole-stat">
                                    <div className="stat">{this.state.opens.toLocaleString()}</div>
                                    <div className="label">Impressions</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {tableSection}


                <div className="grid-x grid-padding-x" style={{display : "none"}}>
                    <div className="small-12 cell">
                        <Bar id={"barchart"}
                             data={this.state.singleChartData}
                             height={333}
                             options={
                                 {
                                     plugins: {
                                         legend: {
                                             display: true,
                                         },
                                     },
                                     maintainAspectRatio: false,
                                     scales: {
                                         xAxes: [{
                                             stacked: false
                                         }],
                                         yAxes: [{
                                             stacked: false
                                         }]
                                     }
                                 }
                             }
                        />
                    </div>
                </div>


            </div>

        </div>)


        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.NEWSLETTER}/>

                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>
        )
    }
}

export default NewsletterScreen