import './assets/css/foundation.css'
import './assets/css/foundation-float.css'
import './assets/css/foundation-prototype.css'
import './assets/css/foundation-rtl.css'
import './assets/css/app.css'

import PartnerPortal from "./components/PartnerPortal";

function App() {
  return (
      <div >
        <PartnerPortal />
      </div>
  );
}

export default App;
