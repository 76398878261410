import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS} from "../common/Strings";
import {Link} from "react-router-dom";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import Paper from "@material-ui/core/Paper/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import DatePicker from "react-datepicker";
import Copyright from "../common/Copyright";


class AccountPartnersScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            sort: "name asc",
            partners : [],
            page: 0,
            app: "Gas App",
            rowsPerPage : 25
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleEditDateChange = this.handleEditDateChange.bind(this);
        this.handleAddDateChange = this.handleAddDateChange.bind(this);
        this.imageChanged = this.imageChanged.bind(this);

    }

    updateSort(sortBy) {

        if (sortBy === "name"){
            if (this.state.sort === "date asc" || this.state.sort === "date desc" ){
                this.setState({sort : "name asc"}, () => this.getPartners())
            } else {
                if (this.state.sort === "name asc"){
                    this.setState({sort : "name desc"}, () => this.getPartners())

                } else {
                    this.setState({sort : "name asc"}, () => this.getPartners())
                }
            }
        } else {
            if (this.state.sort === "name asc" || this.state.sort === "name desc" ){
                this.setState({sort : "date asc"}, () => this.getPartners())
            } else {
                if (this.state.sort === "date asc"){
                    this.setState({sort : "date desc"}, () => this.getPartners())

                } else {
                    this.setState({sort : "date asc"}, () => this.getPartners())
                }
            }
        }

    }


    imageChanged(event){
        if (event.target.files.length > 0) {
            var uploadImage = event.target.files[0];

            if (uploadImage != null) {
                this.setState({
                    uploadImage: uploadImage
                });
            }
        }
    }

    componentDidMount() {

        this.getPartners()
    }


    handleEditDateChange(date) {

        this.setState({
            selectedPartner_dealEndDate: date
        });
    }


    handleAddDateChange(date) {

        this.setState({
            dealEndDate: date
        });
    }


    partnerClicked(idx) {
        let selPartner = this.state.matchingPartners[idx]
        this.setState({selectedPartner : selPartner,
            selectedPartner_name : selPartner.name,
            selectedPartner_app : selPartner.app,
            selectedPartner_address1 : selPartner.address1,
            selectedPartner_address2 : selPartner.address2,
            selectedPartner_town : selPartner.town,
            selectedPartner_county : selPartner.county,
            selectedPartner_postcode : selPartner.postcode,
            selectedPartner_telephone : selPartner.telephone,
            selectedPartner_dealEndDate : new Date(selPartner.ded),
            selectedPartner_imagePath : selPartner.logo
        })

    }

    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());

    }

    updateMatching() {
        var matching = []
        if (this.state.search)
        {
            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.partners.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.partners[c].name.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.partners[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.partners
        }

        var partnerTble = matching.map((row, c) => {

            return <tr key={c}  onClick={(idx) => this.partnerClicked(c)}>
                <td>{row.name}</td>
                <td>{row.app}</td>
                <td>{row.address1}</td>
                <td>{row.dealEndDate}</td>
            </tr>

        })

        this.setState({matchingPartners : matching, partnerTableData : partnerTble})
    }


    getPartners() {
        var formData = new FormData();
        formData.append("sort", this.state.sort);

        Axios.post(ENDPOINTS.settings.getPartners, formData)
            .then((response) => {
                if (response.data.success) {


                    this.setState({
                        partners: response.data.partners
                    }, () => this.updateMatching())
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch partners at this time. Please try again later. [101]")
            });
    }



    addPartner() {
        this.setState({addingPartner : true, dealEndDate:  new Date(new Date().setFullYear(new Date().getFullYear() +1))})
    }

    cancelAdd() {
        this.setState({addingPartner : false, name : "", uploadImage: undefined
        })
    }

    cancelUpdate() {
        this.setState({selectedPartner : undefined, selectedPartner_name : "", uploadImage: undefined})
    }

    uploadImage(partnerID){

                var formData = new FormData();
                formData.append("image", this.state.uploadImage);
                formData.append("partnerID", partnerID);

                var context = this;

                Axios.post(ENDPOINTS.settings.uploadLogo, formData, {
                    onUploadProgress : function(data) {
                        if (data.lengthComputable) {
                            var percent = ((data.loaded / data.total) * 100);

                            console.log("UPLOAD: " + percent + "%");
                            context.setState({
                                uploadProgressPercent : percent
                            });
                        }
                    }
                })
                    .then((res) => {

                        if (res.data.success) {




                        } else {
                            var msg = "Error uploading image, please check your connection and try again."
                            if (res.data.error) {
                                msg = res.data.error.replace(/<\/?[^>]+(>|$)/g, "");
                            }
                            alert(msg)
                            this.setState({
                                saving: false
                            })

                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        alert("Error", "An unknown error has occurred. Please try again later. [1170]")
                        context.setState({
                            isUploading : false
                        });
                    });

    }

    onSaveClicked() {
        if (this.state.saving) {
            return
        }

        this.setState({saving : true})

        var formData = new FormData();
        if (this.state.selectedPartner) {
            formData.append("partnerID", this.state.selectedPartner.id);
            formData.append("name", this.state.selectedPartner_name);
            formData.append("app", this.state.selectedPartner_app);
            formData.append("address1", this.state.selectedPartner_address1);
            formData.append("address2", this.state.selectedPartner_address2);
            formData.append("telephone", this.state.selectedPartner_telephone);
            formData.append("town", this.state.selectedPartner_town);
            formData.append("county", this.state.selectedPartner_county);
            formData.append("postcode", this.state.selectedPartner_postcode);
            formData.append("dealEndDate",  this.getDatetimeDate(this.state.selectedPartner_dealEndDate, true));

        } else {
            formData.append("name", this.state.name);
            formData.append("app", this.state.app);
            formData.append("address1", this.state.address1);
            formData.append("address2", this.state.address2);
            formData.append("telephone", this.state.telephone);
            formData.append("town", this.state.town);
            formData.append("county", this.state.county);
            formData.append("postcode", this.state.postcode);
            formData.append("dealEndDate",  this.getDatetimeDate(this.state.dealEndDate, true));

        }


        Axios.post(ENDPOINTS.settings.savePartnerDetails, formData)
            .then((response) => {
                this.setState({saving : false})

                if (response.data.success) {
                    if (this.state.uploadImage) {
                        this.uploadImage(response.data.result)
                    }
                    this.showModal("Saved", "Your changes have been saved.")
                    this.getPartners()
                    this.cancelAdd()
                    this.cancelUpdate()

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save partner info at this time. Please try again later. [104]")
            });

    }


    askToDeletePartner() {
        let buttons = [
            {
                label : "Yes, delete",
                style : "btn-default",
                click : () => {
                    this.deletePartner();
                    this.hideModal();
                }
            },
            {
                label : "No, cancel",
                style : "btn-default",
                click : () => {
                    this.hideModal();
                }
            }
        ];
        this.showModal("Are You Sure?", "This can not be undone!", buttons)
    }

    deletePartner() {

        var formData = new FormData();

        formData.append("partnerID", this.state.selectedPartner.id);

        Axios.post(ENDPOINTS.settings.deletePartner, formData)
            .then((response) => {

                if (response.data.success) {
                    this.showModal("Delete", "The partner has been deleted.")
                    this.getPartners()
                    this.cancelAdd()
                    this.cancelUpdate()

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                this.setState({saving : false})
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not save partner info at this time. Please try again later. [104]")
            });
    }


    render() {

        var mainContent = <div>not set!</div>

        if (this.state.addingPartner) {
            mainContent = <div className="grid-x grid-padding-x setting-container">
                <div className="large 12 cell ">
                    <h2>New Partner Details</h2>
                    <div className="setting-wrap">
                        <div className="grid-x grid-padding-x">
                            <div className="cell large-12">

                                <span className="label">Name</span>
                                <input type="text" className="settings-field"  name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange} />

                                <span className="label">App</span>
                                <select  className="settings-field" value={this.state.app} name="app" onChange={this.handleChange}>
                                    <option>Gas App</option>
                                    <option>Plumb App</option>
                                    <option>Both</option>
                                </select>

                                <span className="label">Address1</span>
                                <input type="text" className="settings-field"  name="address1" placeholder="Address line 1" value={this.state.address1} onChange={this.handleChange} />

                                <span className="label">Address2</span>
                                <input type="text" className="settings-field"   name="address2" placeholder="Address line 2" value={this.state.address2} onChange={this.handleChange} />

                                <span className="label">Town</span>
                                <input type="text" className="settings-field"   name="town" placeholder="Town" value={this.state.town} onChange={this.handleChange} />

                                <span className="label">County</span>
                                <input type="text" className="settings-field"   name="county" placeholder="County" value={this.state.county} onChange={this.handleChange} />

                                <span className="label">Postcode</span>
                                <input type="text" className="settings-field"   name="postcode" placeholder="Postcode" value={this.state.postcode} onChange={this.handleChange} />

                                <input type="hidden" className="settings-field"   name="telephone" placeholder="Telephone" value={this.state.telephone} onChange={this.handleChange} />

                                <span className="label">Logo</span>
                                <input type={"file"}  onChange={this.imageChanged } />

                                <span className="label">Deal end date</span>

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="settings-field"
                                    selected={this.state.dealEndDate}
                                    onChange={(e) => this.handleAddDateChange(e)}
                                />
                                <div></div>
                                <button type="submit" className="btn" onClick={ () => this.onSaveClicked() } >{this.state.saving ? "Saving..." : "Add Partner"}</button> <button type="submit" className="btn" onClick={ () => this.cancelAdd() } >Cancel</button>
                            </div></div></div>
                </div>
            </div>
        } else if (this.state.selectedPartner) {
            mainContent = <div className="grid-x grid-padding-x setting-container">
                <div className="large 12 cell ">
                    <h2>Edit Partner Details</h2>
                    <div className="setting-wrap">
                        <div className="grid-x grid-padding-x">
                            <div className="cell large-12">

                                <span className="label">Name</span>
                                <input type="text" className="settings-field"  name="selectedPartner_name" placeholder="Name" value={this.state.selectedPartner_name} onChange={this.handleChange} />

                                <span className="label">App</span>
                                <select  className="settings-field" value={this.state.selectedPartner_app} name="selectedPartner_app" onChange={this.handleChange}>
                                    <option>Gas App</option>
                                    <option>Plumb App</option>
                                    <option>Both</option>
                                </select>

                                <span className="label">Address1</span>
                                <input type="text" className="settings-field"  name="selectedPartner_address1" placeholder="Address line 1" value={this.state.selectedPartner_address1} onChange={this.handleChange} />

                                <span className="label">Address2</span>
                                <input type="text" className="settings-field"   name="selectedPartner_address2" placeholder="Address line 2" value={this.state.selectedPartner_address2} onChange={this.handleChange} />

                                <span className="label">Town</span>
                                <input type="text" className="settings-field"   name="selectedPartner_town" placeholder="Town" value={this.state.selectedPartner_town} onChange={this.handleChange} />

                                <span className="label">County</span>
                                <input type="text" className="settings-field"   name="selectedPartner_county" placeholder="County" value={this.state.selectedPartner_county} onChange={this.handleChange} />

                                <span className="label">Postcode</span>
                                <input type="text" className="settings-field"   name="selectedPartner_postcode" placeholder="Postcode" value={this.state.selectedPartner_postcode} onChange={this.handleChange} />

                                <input type="hidden" className="settings-field"   name="selectedPartner_telephone" placeholder="Telephone" value={this.state.selectedPartner_telephone} onChange={this.handleChange} />

                                <span className="label">Change Logo (leave blank to keep existing logo)</span>
                                <input type={"file"}  onChange={this.imageChanged } />


                                <span className="label">Deal end date</span>

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="settings-field"
                                    selected={this.state.selectedPartner_dealEndDate}
                                    onChange={(e) => this.handleEditDateChange(e)}
                                />
                                <div></div>

                                <button type="submit" className="btn" onClick={ () => this.onSaveClicked() } >{this.state.saving ? "Saving..." : "Save Changes"}</button> <button type="submit" className="btn" onClick={ () => this.cancelUpdate() } >Cancel</button>

                                <button style={{float : "right"}} type="submit" className="btn" onClick={ () => this.askToDeletePartner() } >Delete Partner</button>
                            </div></div></div>
                </div>
            </div>
        } else {
            mainContent =<div className="grid-x grid-padding-x table-container">
                <div className="large 12 cell ">
                    <h2>Partners</h2>
                    <button className="btn add-user" onClick={() => this.addPartner()}>Add a Partner</button>
                </div>
                <div className="large-6 cell table-total">
                    {this.state.partners &&
                    <span>{this.state.partners.length} Total Partners</span>
                    }
                </div>
                <div className="large-6 cell">
                    <input type="text" placeholder="Search" className="search" onChange={this.handleSearchTextChange}  name="search" value={this.state.search}  />
                </div>

                <div className="large-12 cell table">
                    <table>
                        <thead>
                        <th onClick={() => this.updateSort("name")}>{this.state.sort === "name asc" && "▼"}{this.state.sort === "name desc" && "▲"}Name</th>
                        <th>App</th>
                        <th>Address 1</th>
                        <th onClick={() => this.updateSort("date")}>{this.state.sort === "date asc" && "▼"}{this.state.sort === "date desc" && "▲"}Deal End Date</th>

                        </thead>
                        <tbody>
                        {this.state.partnerTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.partnerTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </tbody>
                    </table>
                </div>

                <div className="small-12 cell table-nav">
                    {this.state.partnerTableData &&
                    <span>Showing {this.state.partnerTableData.length < this.state.rowsPerPage ? this.state.partnerTableData.length : this.state.rowsPerPage} of {this.state.partners.length}</span>
                    }
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component={Paper}
                        count={this.state.partnerTableData === undefined ? 0 : this.state.partnerTableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />
                </div>
            </div>
        }

        return (  <>
            <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.SETTINGS} />


            <div className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="large-12 cell">
                            <h1>Account Settings</h1>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x settings-menu-container">
                        <div className="large-12 cell">
                            <Link to={"/settings/"}><button className="btn">Company Details</button></Link> <Link to={"/settings/security"}><button className="btn">Security</button></Link> {this.props.user.isAdmin === 1 && <Link to={"/settings/users"}><button className="btn">Users</button></Link>} {this.props.user.isAdmin === 1 && <button className="btn selected">Partners</button>}
                        </div>
                    </div>

                    {mainContent}

                </div>
            </div>
            <div className="footer">
                <Copyright />
            </div>
            <AlertModal
                open={ (this.state.modal != null) ? this.state.modal.open : false }
                title={ (this.state.modal != null) ? this.state.modal.title : "" }
                content={ (this.state.modal != null) ? this.state.modal.content : [] }
                buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                dismissHandler={ () => this.modalWasDismissed() }
            />
        </>)


    }
}

export default AccountPartnersScreen