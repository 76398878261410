import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS, PIE_COLOURS, DATA} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import AlertModal from "../common/AlertModal";
import {Bar, Pie} from "react-chartjs-2";
import {Button} from "react-foundation";
import Paper from "@material-ui/core/Paper/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Copyright from "../common/Copyright";

class ManualsScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            views: "-",
            barChartData: [],
            data,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
        };

    }

    exportData() {
        let includeBrand=true
        let report = REPORTS.MANUALS
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (includeBrand) {
            formData.append("brandID", this.state.brandsData[this.state.selectedBrand].id);
            var source = DATA.GASAPP
            if (this.state.brandsData[this.state.selectedBrand].db.includes("plumb")) {
                source = DATA.PLUMBAPP
            }
            formData.append("source", source);
        }
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }



    dateOrDaysChanged() {
        this.getAllManualsData()
        if (this.state.selectedBrand !== undefined) {
            this.getBrandDetails()
        }
    }

    getAllManualsData() {

        this.getManualQuickStats()
        this.getBrandsTableData()

    }




    getBrandsTableData(){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.manuals.getBrandsData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        brandsData: response.data.brands,
                    }, () => this.updateBrandsElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    getManualsTableData(brandID){
        var formData = new FormData();
        var source = DATA.GASAPP
        if (this.state.brandsData[this.state.selectedBrand].db.includes("plumb")) {
            source = DATA.PLUMBAPP
        }
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        formData.append("brandID", brandID);
        formData.append("source", source);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.manuals.getManualsData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        manualsData: response.data.manuals,
                    }, () => this.updateManualsElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    brandClicked(idx) {

        this.setState({selectedBrand : idx}, () => this.getBrandDetails())

    }

    getBrandDetails() {

        this.getManualQuickStats(this.state.brandsData[this.state.selectedBrand].id)
        this.getManualsTableData(this.state.brandsData[this.state.selectedBrand].id)
     }



    changeBrand(change) {
        var newBrand = this.state.selectedBrand + change
        if (newBrand < 0) {
            newBrand = this.state.brandsData.length -1
        }
        if (newBrand >= this.state.brandsData.length) {
            newBrand = 0
        }

        this.setState({selectedBrand : newBrand, brandDetails: undefined}, () => this.getBrandDetails())
    }


    clearBrand() {

        this.setState({selectedBrand: undefined, brandDetails: undefined})
    }

    updateManualsElems() {
        var pcIDX = 0
        var pieBGs = []
        var pieLabels = []
        var pieData = []
        var totalViews = 0
        for (var x=0;x<this.state.manualsData.length;x++){
            totalViews += parseInt(this.state.manualsData[x].views);
        }
        var manualsTarble = this.state.manualsData.map((row, c) => {



            return <tr key={c}>
                <td>{row.name}</td>
                <td>{parseInt(row.views).toLocaleString()}</td>
                <td>{totalViews > 0 && Math.floor(((row.views / totalViews) * 1000))/10 + "%" }</td>

            </tr>

        })



        this.setState({manualsTable : manualsTarble })
    }

    updateBrandsElems() {

        var pcIDX = 0
        var pieBGs = []
        var pieLabels = []
        var pieData = []
        var totalViews = 0
        for (var x=0;x<this.state.brandsData.length;x++){
            totalViews += parseInt(this.state.brandsData[x].views)
        }
        var brandsTarble = this.state.brandsData.map((row, c) => {

            let app = row.db === "plumbapp" ? " [PA]" : " [GA]"



            return <tr key={c} onClick={(idx) => this.brandClicked(c)}>
                <td>{row.name}{app}</td>
                <td>{parseInt(row.views).toLocaleString()}</td>
                <td>{totalViews > 0 && Math.floor(((row.views / totalViews) * 1000))/10 + "%"}</td>
            </tr>

        })



        this.setState({brandsTable : brandsTarble})
    }


    getManualQuickStats(brand){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (brand !== undefined) {
            formData.append("brandID", brand);
        }
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.manuals.getQuickStats, formData)
            .then((response) => {
                if (response.data.success) {

                    if (brand !== undefined) {
                        this.setState({
                            brandViews: parseInt(response.data.views),
                        });
                    } else {
                        this.setState({
                            views: parseInt(response.data.views),
                        });
                    }

                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }


    componentDidMount() {
        this.dateOrDaysChanged()
    }



    render() {

        var body = [];

        if (this.state.selectedBrand !== undefined) {
            body.push(<div id="divToPrint" className="main-body">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearBrand() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>
                            <h1>Manuals</h1>
                            <div className="detail-title">
                                <h2>{this.state.brandsData[this.state.selectedBrand].name} {this.state.brandsData[this.state.selectedBrand].db === "plumbapp" ? " [PA]" : " [GA]"}</h2>
                            </div>
                        </div>
                        <div className="small-3">
                            <Button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt="" />Export Data
                            </Button>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Manuals views for this brand in this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.brandViews && this.state.brandViews.toLocaleString()}</div>
                                        <div className="label">Views</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x table-container">
                        <div className="small-12 cell ">
                            <h2>Appliance Manuals</h2>
                        </div>
                        <div className="small-6 cell table-total">
                            <span>{this.state.manualsData ? this.state.manualsData.length : "-" } Total Manuals</span>
                        </div>
                        <div className="small-6 cell">

                        </div>

                        <div className="small-12 large-12 cell table">
                            <table>
                                <thead>
                                <th>Appliance</th>
                                <th>No of views</th>
                                <th>Percentage</th>
                                </thead>
                                <tbody>
                                {this.state.manualsTable === undefined ? <tr><td>Loading...</td></tr> : this.state.manualsTable.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </tbody>
                            </table>
                            <div className="small-12 cell table-nav">

                                <TablePagination
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.manualsData === undefined ? 0 : this.state.manualsData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {
                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>
                        </div>

                    </div>




                </div>

            </div>)
        } else {
            body.push(<div className="main-body">
                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Manuals</h1>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Manuals views in this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.views.toLocaleString()}</div>
                                        <div className="label">Views</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x table-container">
                        <div className="small-12 cell ">
                        </div>
                        <div className="small-6 cell table-total">
                        </div>
                        <div className="small-6 cell">

                        </div>

                    <div className="small-12 large-12 cell table">
                        <table>
                            <thead>
                            <th>Brand</th>
                            <th>No of views</th>
                            <th>Percentage</th>
                            </thead>
                            <tbody>
                            {this.state.brandsTable}

                            </tbody>
                        </table>
                        <div className="small-12 cell table-nav">

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    component={Paper}
                                    count={this.state.brandsData === undefined ? 0 : this.state.brandsData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                        </div>
                    </div>

                    
                    
                </div>






                </div>

            </div>)
        }





        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.MANUALS}/>

                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>
            )
    }
}

export default ManualsScreen
