import React from 'react'
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import {Link} from "react-router-dom";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import { Button } from "react-foundation";


class LoginScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            email : "",
            password : ""
        };

        this.handleFormKeyPress = this.handleFormKeyPress.bind(this);

        //this.loginBox = React.createRef();

    }


    componentDidMount() {
    }


    onLoginClicked() {
        if (this.state.email.length > 0 && this.state.password.length > 0) {
            var formData = new FormData();
            formData.append("email", this.state.email);
            formData.append("password", this.state.password);

            Axios.post(ENDPOINTS.login.submit, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.props.loginCallback(response.data.data.user, response.data.data.authenticationToken)
                    } else {
                        console.log(response.data.error);
                        this.showModal("Error", response.data.error);
                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);

                    this.showModal("Error", "Could not login at this time. Please try again later. [100]")
                });
        } else {
            console.log("VALIDATION ERROR");
            this.showModal("Error", "Please provide an Email Address and Password");
        }
    }

    handleFormKeyPress(e) {
        if (e.key === 'Enter') {
            this.onLoginClicked();
        }
    }


    render() {

            return (<div className="loginbg">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className="large-4 medium-2 small-0 cell"></div>
                        <div className="large-4 medium-8 small-12 cell">
                            <img src={require("../../assets/img/gas_app_partners_large_icon.svg").default} alt="Gas App Partners Portal Logo" />

                        </div>
                        <div className="large-4 medium-2 small-0 cell"></div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="large-4 medium-2 small-0 cell"></div>
                        <div className="large-4 medium-8 small-12 cell">
                            <div className="loginbox">
                                <h3>Login</h3>
                                <label>Email</label>
                                <input type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} onKeyPress={this.handleFormKeyPress} />
                                <label>Password</label>
                                <input type="password" name="password" placeholder="Password"  value={this.state.password} onChange={this.handleChange} onKeyPress={this.handleFormKeyPress} />
                                <Button type="submit" className="button" onClick={ () => this.onLoginClicked() } >Login</Button>
                                <Link to={"/forgotten"} className="forgotpass">Forgotten password?</Link>
                            </div>
                        </div>
                        <div className="large-4 medium-2 small-0 cell"></div>
                    </div>

                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>)


    }
}

export default LoginScreen;