import React from "react";
import DatePicker from  "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

function PartnerDateSelector(props) {

    var customDates = <></>
    if (props.overDays < 0){
        customDates = <div className="dateselect">
        <div className="datefrom">
        <label>From:</label> 
        <DatePicker
            dateFormat="dd/MM/yyyy"
            className="edit-field"
            selected={props.fromDate}
            onChange={props.handleFromDateChange}
        />
        </div>
        <div className="dateto">
            <label> To: </label> <DatePicker
                dateFormat="dd/MM/yyyy"
                className="edit-field"
                selected={props.toDate}
                onChange={props.handleToDateChange}
            />
            </div>
            </div>
    }
    return <><select   className="short-select" value={props.overDays} name="overDays" onChange={props.handleOverDaysChange}>
        <option value={30}>Last 30 days</option>
        <option value={60}>Last 60 days</option>
        <option value={90}>Last 90 days</option>
        <option value={-1}>Custom</option>
    </select>{customDates}</>;
}

export default PartnerDateSelector;
