import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS, PIE_COLOURS, VIEW, DATA} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import {Button} from "react-foundation";
import {Bar, Pie, Line} from "react-chartjs-2";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper/Paper";
import Copyright from "../common/Copyright";

class LiveloungeScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            viewers: "-",
            barChartData: [],
            data,
            viewType: VIEW.DETAIL,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);

    }

    exportData() {

        let report = REPORTS.LIVELOUNGE
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }


    setViewType(type) {

        this.setState({viewType: type})
    }

    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateVideosElems());

    }


    dateOrDaysChanged() {
        this.getAllLoungeData()
    }

    getAllLoungeData() {

        this.getLoungeQuickStats()
        this.getTableData()

    }

    getTableData(){
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.liveLounge.getVideosData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        videoData: response.data.videos,
                    }, () => this.updateVideosElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    updateVideosElems() {

        var matching = []
        if (this.state.search)
        {
            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.videoData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.videoData[c].video_title.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.videoData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.videoData === null ? [] : this.state.videoData
        }
        var pcIDX = 0
        var pieBGs = []
        var pieLabels = []
        var pieData = []
        var totalViews = 0

        var vidsTble = matching.map((row, c) => {

            totalViews = totalViews + parseInt(row.viewers === null ? 0 : row.viewers)
            let app = row.db === "plumbapp" ? " [PA]" : " [GA]"


            return <tr key={c} onClick={(idx) => this.vidClicked(c)}>
                <td>{row.video_title}{app}</td>
                <td>{row.date}</td>
                <td>{row.time}</td>
                <td>{row.duration} mins.</td>
                <td>{row.viewers.toLocaleString()}</td>
                <td>{row.questions}</td>
            </tr>

        })
        var smallVidsTble = matching.map((row, c) => {
            let app = row.db === "plumbapp" ? " [PA]" : " [GA]"


            return <tr key={c} onClick={(idx) => this.vidClicked(c)}>
                <td>{row.video_title}{app}</td>
                <td>{row.viewers.toLocaleString()}</td>
                <td>{row.questions}</td>
            </tr>

        })


        this.setState({viewers: totalViews, videosTableData : vidsTble, smallVideosTableData : smallVidsTble,matchingVideos : matching})
    }



    vidClicked(vidIDX){
        this.setState({selectedVideo : vidIDX}, () => this.getFullDetails())
    }

    changeVid(change) {
        var newOne = this.state.selectedVideo + change
        if (newOne < 0) {
            newOne = this.state.matchingVideos.length -1
        }
        if (newOne >= this.state.matchingVideos.length) {
            newOne = 0
        }

        this.setState({selectedVideo : newOne}, () => this.getFullDetails())
    }

    clearVid() {

        this.setState({selectedVideo: undefined})
    }

    getFullDetails() {
        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("videoID", this.state.matchingVideos[this.state.selectedVideo].id);
        formData.append("startDate", this.state.matchingVideos[this.state.selectedVideo].start_date);
        formData.append("duration", this.state.matchingVideos[this.state.selectedVideo].duration);
        var source = DATA.GASAPP
        if ( this.state.matchingVideos[this.state.selectedVideo].db.includes("plumb")) {
            source = DATA.PLUMBAPP
        }
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        formData.append("source", source);
        Axios.post(ENDPOINTS.liveLounge.getBarChartData, formData)
            .then((response) => {
                if (response.data.success) {

                    const data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                label: 'Viewers',
                                data: response.data.values,
                               // backgroundColor: this.state.gradients[this.state.gradients.length-1],
                                borderColor: this.state.gradients[this.state.gradients.length-1],
                                pointRadius :0,
                                borderWidth: 1,
                                tension: .5 // disables bezier curves

                            },
                        ],

                    };

                    this.setState({
                        singleChartData: data
                    });
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    getLoungeQuickStats(){
        /* var formData = new FormData();

         formData.append("overDays", this.state.overDays);
         formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
         Axios.post(ENDPOINTS.liveLounge.getQuickStats, formData)
             .then((response) => {
                 if (response.data.success) {

                     this.setState({
                         viewers: response.data.viewers
                     });
                 } else {
                     console.log(response.data.error);

                 }
             })
             .catch(error => {
                 console.log("ERROR");
                 console.log(error);
             });
*/
    }


    componentDidMount() {
        this.setUpGradients("barchart")
    }

    render() {

        var body = [];

        var tableSection = []

        if (this.state.selectedVideo !== undefined) {
            body.push(<div className="main-body">
                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearVid() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>
                            <h1>Live Lounge</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changeVid(-1) }></span>
                                <h2>{this.state.matchingVideos[this.state.selectedVideo].video_title}{this.state.matchingVideos[this.state.selectedVideo].db === "plumbapp" ? " [PA]" : " [GA]"}</h2>
                                <span className="next" onClick={ () => this.changeVid(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">


                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Stats</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-2">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.matchingVideos[this.state.selectedVideo].viewers.toLocaleString()}</div>
                                        <div className="label">Views</div>
                                    </div>
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.matchingVideos[this.state.selectedVideo].questions.toLocaleString()}</div>
                                        <div className="label">Questions</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="grid-x grid-padding-x detail-container">
                        <div className="small-12 cell">
                            <h2>Viewers over time</h2>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <Line id={"barchart2"}
                                         data={this.state.singleChartData}
                                         height={333}
                                         options={
                                             {
                                                 plugins: {
                                                     legend: {
                                                         display: true,
                                                     },
                                                 },
                                                 maintainAspectRatio: false,
                                                 scales: {
                                                     xAxes: [{
                                                         stacked: false
                                                     }],
                                                     yAxes: [{
                                                         stacked: false
                                                     }]
                                                 }
                                             }
                                         }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        } else {


            if (this.state.viewType === VIEW.PIE) {
                tableSection.push(<div className="grid-x grid-padding-x table-container">
                    <div className="small-12 cell ">
                        <h2>Broadcasts</h2>
                    </div>
                    <div className="small-6 cell table-total">
                        <span>{this.state.videoData && this.state.videoData.length} Total broadcasts during this period</span>
                    </div>
                    <div className="small-6 cell">

                    </div>
                    <div className="large-12 small-12 cell table">
                        <table>
                            <thead>
                            <th>Broadcast title</th>
                            <th>Total Viewers</th>
                            <th>No of Questions</th>
                            </thead>
                            <tbody>
                            {this.state.smallVideosTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.smallVideosTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}

                            </tbody>
                        </table>

                        <div className="grid-x grid-padding-x">


                            <div className="small-12 cell table-nav">

                                <TablePagination
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}

                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.smallVideosTableData === undefined ? 0 : this.state.smallVideosTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>

                        </div>


                    </div>


                </div>)
            } else {
                tableSection.push(<div className="grid-x grid-padding-x table-container">
                    <div className="small-12 cell ">
                        <h2>Broadcasts</h2>
                    </div>
                    <div className="small-6 cell table-total">
                        <span>{this.state.videoData && this.state.videoData.length} Total broadcasts during this period</span>
                    </div>
                    <div className="small-6 cell">
                        <input type="text" placeholder="Search" className="search" onChange={this.handleSearchTextChange}  name="search" value={this.state.search}  />
                    </div>

                    <div className="small-12 cell table">
                        <table>
                            <thead>
                            <th>Broadcast Title</th>
                            <th>Date</th>
                            <th>Start Time</th>
                            <th>Duration</th>
                            <th>Total Viewers</th>
                            <th>No of Questions</th>
                            </thead>
                            <tbody>
                            {this.state.videosTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.videosTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}

                            </tbody>
                        </table>
                        <div className="grid-x grid-padding-x">


                            <div className="small-12 cell table-nav">

                                <TablePagination
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.videosTableData === undefined ? 0 : this.state.videosTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>

                        </div>
                    </div>

                </div>)
            }



            body.push(<div id="divToPrint"  className="main-body">


                <div className="grid-container">


                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Live Lounge</h1>
                        </div>
                        <div className="small-3">
                            <Button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt="" />Export Data
                            </Button>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total viewers in this time period</h2>
                            <p><b>PLEASE NOTE: This figure reflects the number of live viewers from the app at time of broadcast.<br/>

                                Head to the ‘Misc’ report tab to find your final views in the image taken from Youtube where users were able to tune in live or watch back.</b></p>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.viewers.toLocaleString()}</div>
                                        <div className="label">Viewers</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {tableSection}








                    <div className="grid-x grid-padding-x" style={{display : "none"}}>
                        <div className="small-12 cell">
                            <Bar id={"barchart"}
                                 data={this.state.singleChartData}
                                 height={333}
                                 options={
                                     {
                                         plugins: {
                                             legend: {
                                                 display: true,
                                             },
                                         },
                                         maintainAspectRatio: false,
                                         scales: {
                                             xAxes: [{
                                                 stacked: false
                                             }],
                                             yAxes: [{
                                                 stacked: false
                                             }]
                                         }
                                     }
                                 }
                            />
                        </div>
                    </div>


                </div>

            </div>)
        }



        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.LIVELOUNGE}/>

                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>
        )

    }
}

export default LiveloungeScreen
