import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, REPORTS, PIE_COLOURS,VIEW} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import AlertModal from "../common/AlertModal";
import {Bar,Pie} from "react-chartjs-2";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Paper from "@material-ui/core/Paper/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {Button} from "react-foundation";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Copyright from "../common/Copyright";



class MerchantScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");

        this.state = {
            overDays : 30,
            views: "-",
            likes: "-",
            viewType: VIEW.DETAIL,
            barChartData: [],
            data,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() -1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
        };

        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handlePieLikesViewsChange = this.handlePieLikesViewsChange.bind(this);
    }


    dateOrDaysChanged() {
        this.getAllMerchData()
    }


    handlePieLikesViewsChange(event) {

        const target = event.target
        const value = target.value
        this.setState({
            pieLikesViews: value
        }, () => this.updateMatching());
    }


    getAllMerchData() {

        this.getMerchQuickStats()
        this.getTableData()

    }

    getMerchQuickStats(){

    }



    dealClicked(dealIDX){
        this.setState({selectedDeal : dealIDX}, () => this.getFullDealDetails())
    }

    updateMatching() {

        var matching = []
        var pcIDX = 0
        if (this.state.search)
        {
            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.dealData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.dealData[c].title.toLowerCase().includes(searchTerms[i].toLowerCase()) ) {
                        matching.push(this.state.dealData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.dealData
        }

        var totalViews = 0
         var pieLabels = []
        var pieData = []
        var pieBGs = []
        var dealsTble = matching.map((row, c) => {

            var add = 0
            if (row.views !== null) {
                add = parseInt(row.views)
            }
            totalViews = totalViews + add

            var app = "[Both]"
            if (row.app !== "both") {
                app =  row.app === "gas" ? "[GA]" : "[PA]"
            }

            return <tr key={c} onClick={(idx) => this.dealClicked(c)}>


                <td>{row.title} {app}</td>
                <td>{row.startDate}</td>
                <td>{row.endDate}</td>
                <td>{row.views === null ? 0 : row.views.toLocaleString()}</td>
                 <td>{row.price ? row.price.replace(" null", "").replace("null ", "") : ""}</td>

            </tr>

        })

        if (matching.length === this.state.dealData.length){
            this.setState({views: totalViews})
        }


        this.setState({dealsTableData : dealsTble, matchingDeals : matching})
    }


    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());

    }

    getTableData(){

        var formData = new FormData();
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.merchant.getMerchantDeals, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        dealData: response.data.deals
                    }, () => this.updateMatching())
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }



    getFullDealDetails(){

        var formData = new FormData();
        formData.append("dealID", this.state.dealData[this.state.selectedDeal].massiveDealId);

        Axios.post(ENDPOINTS.merchant.getFullDealDetails, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        dealtails: response.data.deal
                    })
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    exportData() {

        let report = REPORTS.MERCHANT
        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('screen', report);
        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);
        if (this.state.overDays  < 0) {
            formData.append("fromDate",  this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios
            .post(ENDPOINTS.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, report+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }







    componentDidMount() {
        this.dateOrDaysChanged()
    }


    changeDeal(change) {
        var newDeal = this.state.selectedDeal + change
        if (newDeal < 0) {
            newDeal = this.state.matchingDeals.length -1
        }
        if (newDeal >= this.state.matchingDeals.length) {
            newDeal = 0
        }

        this.setState({selectedDeal : newDeal, dealtails: undefined}, () => this.getFullDealDetails())
    }

    clearDeal() {

        this.setState({selectedDeal: undefined, dealtails: undefined})
    }


    render() {

        var body = [];

        var tableSection = []
        if (this.state.selectedDeal !== undefined) {
            body.push(<div className="main-body">
                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearDeal() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>
                            <h1>Wolseley Deals</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changeDeal(-1) }></span>
                                <h2>{this.state.matchingDeals[this.state.selectedDeal].title}</h2>
                                <span className="next" onClick={ () => this.changeDeal(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">


                        </div>
                    </div>


                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Total Stats</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.matchingDeals[this.state.selectedDeal].views ? this.state.matchingDeals[this.state.selectedDeal].views.toLocaleString() : "0"}</div>
                                        <div className="label">Views</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="grid-x grid-padding-x detail-container">
                        <div class="small-12 cell">
                            <h2>Deal Details</h2>
                            <div class="detail-wrap">
                                <div class="grid-x grid-padding-x">
                                    <div class="cell small-12 medium-12 large-6">
                                        <h3>Title</h3>
                                        <p>
                                            {this.state.matchingDeals[this.state.selectedDeal].title}
                                        </p>

                                        {this.state.dealtails && this.state.dealtails.longDescription && <><h3>Product description</h3><p>{this.state.dealtails.longDescription}</p></>}
                                        {this.state.dealtails && this.state.dealtails.priceHeadline && <><h3>Price headline</h3><p>{this.state.dealtails.priceHeadline}</p></>}

                                        <h3>Start Date</h3><p>{this.state.matchingDeals[this.state.selectedDeal].startDate}</p>
                                        <h3>End Date</h3><p>{this.state.matchingDeals[this.state.selectedDeal].endDate}</p>


                                    </div>
                                    <div class="cell small-12 medium-12 large-6">
                                        {this.state.images &&
                                        <div className="image-gal">
                                            <img src="https://www.juliebergan.no/sites/g/files/g2000006326/f/sample-4.jpg"
                                                 alt=""/>
                                            <img src="https://www.juliebergan.no/sites/g/files/g2000006326/f/sample-4.jpg"
                                                 alt=""/>
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>



            </div>)
        } else {
            if (this.state.viewType === VIEW.PIE) {

            } else {
                tableSection.push(<div className="grid-x grid-padding-x table-container">
                    <div className="small-12 cell ">
                        <h2>Wolseley Deals</h2>
                    </div>
                    <div className="small-6 cell table-total">
                        <span>{this.state.dealData && this.state.dealData.length} Total deals during this period</span>
                    </div>
                    <div className="small-6 cell">
                        <input type="text" placeholder="Search" className="search" onChange={this.handleSearchTextChange}  name="search" value={this.state.search}  />
                    </div>

                    <div className="small-12 cell table">
                        <table>
                            <thead>
                            <th>Deal Title</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>No of Views</th>
                             <th>Price</th>
                            </thead>
                            <tbody>
                            {this.state.dealsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.dealsTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}

                            </tbody>
                        </table>
                        <div className="grid-x grid-padding-x">

                            
                            <div className="small-12 cell table-nav">

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.smallDealsTableData === undefined ? 0 : this.state.smallDealsTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count}`}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    
                </div>)
            }

            body.push(<div id="divToPrint" className="main-body">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Wolseley Deals</h1>
                        </div>
                        <div className="small-3">
                            <button className="button export-btn" onClick={() => this.askWhichFile()}><img src={require("../../assets/img/export.svg").default} alt="" />Export Data
                            </button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Quick stats during this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.views.toLocaleString()}</div>
                                        <div className="label">Views</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {tableSection}





                </div>

            </div>)
        }





        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} report={REPORTS.MERCHANT}/>

                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>

        )
    }
}

export default MerchantScreen
