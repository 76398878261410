import React from 'react'
import {Link} from "react-router-dom";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import { Button } from "react-foundation";


class PasswordResetRequestScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            email : ""
        };
    }


    componentDidMount() {
    }

    requestWasSubmitted() {
        if (this.state.networkInFlight) return;

        var validationResult = this.validateCreateFormData(this.state, [
            {
                key : "email",
                label : "Email Address",
                postName : "email"
            }
        ]);

        if (validationResult.success) {
            this.setState({
                networkInFlight : true
            });

            Axios.post(ENDPOINTS.login.requestPasswordReset, validationResult.formData)
                .then((response) => {
                    let resp = API.parseResponse(response);
                    if (resp.success) {
                        this.setState({
                            requestSuccessful : true,
                            networkInFlight : false
                        });
                        this.showModal("Password reset request received", "If the email exists in our system, you will receive an email with instructions on resetting your password. If you do not receive an email, please check your Spam folder.")

                    } else {
                        this.setState({
                            requestSuccessful : false,
                            networkInFlight : false
                        });

                        this.showModal("Error", resp.error);
                    }
                })
                .catch((err) => {
                    this.setState({
                        requestSuccessful : false,
                        networkInFlight : false
                    });

                    this.showModal("Error", "An unknown error occurred. [900]");
                });
        } else {
            this.showModal("Error", validationResult.error);
        }
    }

    render() {

        return (<div className="loginbg">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="large-4 medium-2 small-0 cell"></div>
                    <div className="large-4 medium-8 small-12 cell">
                        <img src={require("../../assets/img/gas_app_partners_large_icon.svg").default} alt="Gas App Partners Portal Logo" />

                    </div>
                    <div className="large-4 medium-2 small-0 cell"></div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="large-4 medium-2 small-0 cell"></div>

                    <div className="large-4 medium-8 small-12 cell">
                        <div className="loginbox">
                            <h3>Password Reset</h3>
                            <label>Email Address</label>
                            <input type="text" name="email" placeholder="Email" className="form-control" value={this.state.email} onChange={this.handleChange} />


                            <Button type="submit" className="button" onClick={ () => this.requestWasSubmitted() } >Request Password Reset</Button>

                            <Link to={"/"} className="forgotpass">Return to Login</Link>
                        </div>
                    </div>
                    <div className="large-4 medium-2 small-0 cell"></div>
                </div>

            </div>
            <AlertModal
                open={ (this.state.modal != null) ? this.state.modal.open : false }
                title={ (this.state.modal != null) ? this.state.modal.title : "" }
                content={ (this.state.modal != null) ? this.state.modal.content : [] }
                buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                dismissHandler={ () => this.modalWasDismissed() }
            />
        </div>)


    }
}

export default PasswordResetRequestScreen;