export const SECTIONS = {
    REPORTS : "Reports",
    SETTINGS : "Settings"
};

export const DATA = {
    GASAPP : "Gas App",
    PLUMBAPP : "Plumb App",
    BOTH : "Both"
};

export const REPORTS = {
    ACTIVEUSERS : "Active Users",
    MERCHANT : "Wolseley Deals",
    MASSIVE : "The Vault",
    MANUALS : "Manuals",
    COMPETITIONS : "Competitions",
    LIVELOUNGE : "Live Lounge",
    NEWSLETTER : "Newsletter",
    BREAKTIME : "Breaktime",
    INSTAGRAM : "Instagram",
    TWITTER : "Twitter",
    FACEBOOK : "Facebook",
    TIKTOK : "Misc"

};

export const VIEW = {
    PIE : "PIE ",
    DETAIL : "DETAIL"
};

export const SOCIALS = {
    FACEBOOK : "Facebook",
    TWITTER : "Twitter",
    INSTAGRAM : "Instagram",
    TIKTOK : "Misc"
}

export const PIE_COLOURS = [
    {start :"rgba(155,216,255,1)", end :"rgba(62,126,255,1)"},
    {start :"rgba(175,253,175,1)", end :"rgba(95,235,52,1)"},
    {start :"rgba(255,226,155,1)", end :"rgba(235,190,52,1)"},
    {start :"rgba(255,155,164,1)", end :"rgba(235,52,86,1)"},
    {start :"rgba(249,155,255,1)", end :"rgba(216,52,235,1)"},
    {start :"rgba(155,255,226,1)", end :"rgba(52,235,159,1)"},
    {start :"rgba(233,255,155,1)", end :"rgba(203,235,52,1)"},
    {start :"rgba(255,176,155,1)", end :"rgba(235,90,52,1)"},
    {start :"rgba(255,155,195,1)", end :"rgba(235,52,125,1)"},
    {start :"rgba(214,155,255,1)", end :"rgba(155,52,235,1)"},
    {start :"rgba(137,137,137,1)", end :"rgba(9,9,9,1)"},
    {start :"rgba(168,170,217,1)", end :"rgba(32,31,88,1)"},
    {start :"rgba(176,211,176,1)", end :"rgba(49,88,37,1)"},
    {start :"rgba(220,206,173,1)", end :"rgba(89,76,35,1)"},
    {start :"rgba(217,184,187,1)", end :"rgba(67,3,15,1)"},
    {start :"rgba(195,172,196,1)", end :"rgba(83,3,93,1)"},
    {start :"rgba(180,214,204,1)", end :"rgba(29,73,71,1)"},
    {start :"rgba(191,199,162,1)", end :"rgba(63,72,23,1)"},
    {start :"rgba(209,172,162,1)", end :"rgba(104,24,2,1)"},
    {start :"rgba(227,173,194,1)", end :"rgba(142,3,59,1)"},
    {start :"rgba(186,164,201,1)", end :"rgba(43,1,75,1)"},
    {start :"rgba(98,209,25,1)", end :"rgba(15,119,44,1)"},

];