import React from 'react'
import { API } from "../networking/API";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginScreen from "./login/LoginScreen";
import PasswordResetRequestScreen from "./login/PasswordResetRequestScreen";
import PasswordResetSubmitScreen from "./login/PasswordResetSubmitScreen";
import ActiveUsersScreen from "./reports/ActiveUsersScreen";
import AccountInfoScreen from "./settings/AccountInfoScreen";
import AccountSecurityScreen from "./settings/AccountSecurityScreen";
import AccountUsersScreen from "./settings/AccountUsersScreen";
import MerchantScreen from "./reports/MerchantScreen";
import ManualsScreen from "./reports/ManualsScreen";
import CompetitionsScreen from "./reports/CompetitionsScreen";
import LiveloungeScreen from "./reports/LiveloungeScreen";
import NewsletterScreen from "./reports/NewsletterScreen";
import BreaktimeScreen from "./reports/BreaktimeScreen";
import {DATA, SOCIALS} from "./common/Strings"
import MassiveScreen from "./reports/MassiveScreen";
import AccountPartnersScreen from "./settings/AccountPartnersScreen";
import SocialScreen from "./reports/SocialScreen"
import NotesScreen from "./reports/NotesScreen"

class PartnerPortal extends React.Component {

    componentDidMount() {

        // If we have a local user already set, reload now
        var user = localStorage.getItem("ppuser");
        var sessionToken = localStorage.getItem("ppauthToken");

        if (user !== null && sessionToken !== null) {
            //alert(user)
            user = JSON.parse(user);

            this.onUserLogin(user, sessionToken);
        }

        // When authentication fails, sign the user out
        API.onAuthFailed(() => {
            this.onUserLogout();
            window.location.href = "/";
        });

        this.onUserLogin = this.onUserLogin.bind(this);
    }


    render() {

        if (this.state == null || this.state.user == null) {
        // Not logged in, force user to login screen
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path={"/forgotten"} exact
                        render={(props) =>
                            <PasswordResetRequestScreen {...props} />
                        }
                         />

                    <Route
                        path={"/forgotten/:token"}
                        render={(props) =>
                            <PasswordResetSubmitScreen {...props} />
                        }
                        />

                    <Route
                        render={(props) =>
                            <LoginScreen loginCallback={(user, sessionToken) => this.onUserLogin(user, sessionToken)}/>
                        }
                         />
                </Switch>
            </BrowserRouter>
        )
        } else {


            return(
                <BrowserRouter>
                    <Switch>
                        <Route
                            path={"/"} exact
                            render={(props) =>
                                <ActiveUsersScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/merchant"} exact
                            render={(props) =>
                                <MerchantScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/massive"} exact
                            render={(props) =>
                                <MassiveScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/manuals"} exact
                            render={(props) =>
                                <ManualsScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/competitions"} exact
                            render={(props) =>
                                <CompetitionsScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/livelounge"} exact
                            render={(props) =>
                                <LiveloungeScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/newsletter"} exact
                            render={(props) =>
                                <NewsletterScreen onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/breaktime"} exact
                            render={(props) =>
                                <BreaktimeScreen onUserLogout={() => this.onUserLogout()} />
                            }
                            />

                        <Route
                            path={"/reports/instagram"} exact
                            render={(props) =>
                                <SocialScreen platform={SOCIALS.INSTAGRAM} onUserLogout={() => this.onUserLogout()} />
                            }
                             />

                        <Route
                            path={"/reports/twitter"} exact
                            render={(props) =>
                                <SocialScreen platform={SOCIALS.TWITTER} onUserLogout={() => this.onUserLogout()} />
                            }
                        />

                        <Route
                            path={"/reports/facebook"} exact
                            render={(props) =>
                                <SocialScreen platform={SOCIALS.FACEBOOK} onUserLogout={() => this.onUserLogout()} />
                            }
                        />

                        <Route
                            path={"/reports/misc"} exact
                            render={(props) =>
                                <SocialScreen platform={SOCIALS.TIKTOK} onUserLogout={() => this.onUserLogout()} />
                            }
                        />




                        <Route
                            path={"/settings"} exact
                            render={(props) =>
                                <AccountInfoScreen onUserLogout={() => this.onUserLogout() } user={this.state.user} />
                            }
                             />
                        <Route
                            path={"/settings/security"} exact
                            render={(props) =>
                                <AccountSecurityScreen onUserLogout={() => this.onUserLogout() }  user={this.state.user} />
                            }
                             />

                        {this.state.user.isAdmin === 1 ? <Route
                                path={"/settings/users"} exact
                                render={(props) =>
                                    <AccountUsersScreen onUserLogout={() => this.onUserLogout()}  user={this.state.user} />
                                }
                            />

                            :
                            <Route
                                path={"/settings/users"} exact
                                render={(props) =>
                                    <AccountInfoScreen onUserLogout={() => this.onUserLogout()}  user={this.state.user} />
                                }
                            />
                        }



                        {this.state.user.isAdmin === 1 ?  <Route
                                path={"/settings/partners"} exact
                                render={(props) =>
                                    <AccountPartnersScreen onUserLogout={() => this.onUserLogout()}  user={this.state.user} />
                                }
                            />

                            :
                            <Route
                                path={"/settings/partners"} exact
                                render={(props) =>
                                    <AccountInfoScreen onUserLogout={() => this.onUserLogout()}  user={this.state.user} />
                                }
                            />
                        }




                        <Route
                            path={"/reports/notes"} exact
                            render={(props) =>
                                <NotesScreen  onUserLogout={() => this.onUserLogout()} />
                            }
                        />
                    </Switch>

                </BrowserRouter>)
        }
    }


    /**
     * General logout handler. Clears up user data and state.
     */
    onUserLogout() {
        localStorage.removeItem("ppuser");
        localStorage.removeItem("ppauthToken");

        API.setAuthenticationToken(null);
        this.setState( {
            user : null
        })
    }


    /**
     * General login handler
     * @param user              User object
     * @param sessionToken      User Authentication Token
     */
    onUserLogin(user, sessionToken) {
        localStorage.setItem("ppuser", JSON.stringify(user));
        if (user.partner.app === DATA.PLUMBAPP) {
            localStorage.setItem("ppdata", DATA.PLUMBAPP);
        } else {
            if (user.partner.app === DATA.GASAPP) {
                localStorage.setItem("ppdata", DATA.GASAPP);
            } else {
                var data = localStorage.getItem("ppdata");
                if (!data){
                    localStorage.setItem("ppdata", DATA.GASAPP);
                }

            }

        }

        if (sessionToken !== undefined) {
            localStorage.setItem("ppauthToken", sessionToken);
            API.setAuthenticationToken(sessionToken);
        }

        this.setState({
            user : user
        })
    }

}


export default PartnerPortal;