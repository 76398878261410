import React from 'react'
import NavBar from "../common/NavBar";
import {SECTIONS, DATA} from "../common/Strings";
import PartnerComponent from "../PartnerComponent";
import AlertModal from "../common/AlertModal";
import {Button} from "react-foundation";
import PartnerDateSelector from "../common/PartnerDateSelector";
import Axios from "axios";
import {ENDPOINTS} from "../../networking/API";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Copyright from "../common/Copyright";

class NotesScreen extends PartnerComponent {

    constructor(props, context) {
        super(props, context);

        var data = localStorage.getItem("ppdata");
        var user = undefined
        var userStr = localStorage.getItem("ppuser");
        if (user !== null) {
            user = JSON.parse(userStr);
        }

        this.state = {
            overDays: 30,
            notes: "-",
            data,
            user: user,
            fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            toDate: new Date(),
            page: 0,
            rowsPerPage : 10,
            partners : [],
        };
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handlePartnerChange = this.handlePartnerChange.bind(this);
    }



    handleSearchTextChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, () => this.updatePostsElems());

    }

    getAllData() {
        this.getPostsTableData()
    }

    getPostsTableData() {
        var formData = new FormData();

        formData.append("overDays", this.state.overDays);
        formData.append("data", this.state.data);

        if (this.state.overDays < 0) {
            formData.append("fromDate", this.getDatetimeDate(this.state.fromDate, true));
            formData.append("toDate", this.getDatetimeDate(this.state.toDate, false));
        }
        Axios.post(ENDPOINTS.notes.getPostsData, formData)
            .then((response) => {
                if (response.data.success) {

                    this.setState({
                        notes : response.data.posts.length,
                        postsData: response.data.posts,
                    }, () => this.updatePostsElems());
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });
    }


    updatePostsElems() {

        var matching = []

        if (this.state.search) {

            var searchTerms = this.state.search.split(" ");
            for (var c = 0; c < this.state.postsData.length; c++) {

                for (var i = 0; i < searchTerms.length; i++) {

                    if (this.state.postsData[c].note.toLowerCase().includes(searchTerms[i].toLowerCase())) {
                        matching.push(this.state.postsData[c])
                        break
                    }
                }
            }

        } else {
            matching = this.state.postsData
        }


        var bigPostsTarble = matching.map((row, c) => {

            return <tr key={c} onClick={(idx) => this.postClicked(c)}>
                <td>{row.note}</td>
                <td>{row.noteDate}</td>
            </tr>

        })



        this.setState({ bigPostsTable: bigPostsTarble})
    }


    postClicked(idx) {

        this.setState({
            selectedPost: idx,
            changedTitle : this.state.postsData[idx].title
        });

    }



    editTitle() {

        var formData = new FormData();

        formData.append("postID", this.state.postsData[this.state.selectedPost].id);
        formData.append("note", this.state.changedNote);

        Axios.post(ENDPOINTS.notes.editPost, formData)
            .then((response) => {
                if (response.data.success) {

                    this.getAllData()
                    this.showModal("Success", "Note updated successfully")
                } else {
                    console.log(response.data.error);

                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);
            });

    }

    clearPost() {

        this.setState({selectedPost: undefined})
    }

    changePost(change) {
        var newPost = this.state.selectedPost + change

        if (newPost < 0) {
            newPost = this.state.postsData.length - 1
        }
        if (newPost >= this.state.postsData.length) {
            newPost = 0
        }

        this.setState({selectedPost: newPost})
    }



    addPost() {

        var formData = new FormData();
        formData.append("note", this.state.note);

        var context = this;

        Axios.post(ENDPOINTS.notes.uploadPost, formData, {

        })
            .then((res) => {

                if (res.data.success) {
                    this.getAllData()
                    this.showModal("Success", "Note added successfully");
                    this.setState({note : ''})
                } else {
                    var msg = "Error uploading note, please check your connection and try again."
                    if (res.data.error) {
                        msg = res.data.error.replace(/<\/?[^>]+(>|$)/g, "");
                    }
                    alert(msg)
                    this.setState({
                        saving: false
                    })

                }
            })
            .catch(function(err) {
                console.log(err);
                alert("Error", "An unknown error has occurred. Please try again later. [1175]")
                context.setState({
                    isUploading : false
                });
            });
    }



    dateOrDaysChanged() {
        this.getAllData()
    }

    componentDidMount() {
        this.getAllData()
        this.getPartners()

    }

    getPartners() {
        var formData = new FormData();

        Axios.post(ENDPOINTS.settings.getPartners, formData)
            .then((response) => {
                if (response.data.success) {

                    let partners = response.data.partners.map((row, c) => {

                        return <option key={c} value={row.id}>{row.name}</option>

                    })

                    this.setState({
                        partners: partners,
                        partnerID : this.state.user.partnerId
                    })
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch users at this time. Please try again later. [101]")
            });
    }


    handlePartnerChange(event) {

        const target = event.target
        const value = target.value


        var formData = new FormData();
        formData.append("partnerID", value);

        Axios.post(ENDPOINTS.settings.adminChangePartner, formData)
            .then((response) => {
                if (response.data.success) {

                    let user = response.data.user

                    localStorage.setItem("ppuser", JSON.stringify(user));
                    if (user.partner.app === DATA.PLUMBAPP) {
                        localStorage.setItem("ppdata", DATA.PLUMBAPP);
                    } else {
                        if (user.partner.app === DATA.GASAPP) {
                            localStorage.setItem("ppdata", DATA.GASAPP);
                        } else {
                            var data = localStorage.getItem("ppdata");
                            if (!data){
                                localStorage.setItem("ppdata", DATA.GASAPP);
                            }

                        }

                    }
                    window.location.reload();

                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not update partner info at this time. Please try again later. [104]")
            });




    }


    deletePost() {

        if (window.confirm("Are you sure? This can not be undone!")) {
            var formData = new FormData();

            formData.append("postID", this.state.postsData[this.state.selectedPost].id);

            Axios.post(ENDPOINTS.notes.deletePost, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.clearPost()
                        this.getAllData()
                        this.showModal("Success", "Note deleted successfully");

                    } else {
                        console.log(response.data.error);

                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);
                });
        }

    }




    render() {

        var body = [];

        if (this.state.selectedPost !== undefined) {
            body.push(<div className="main-body">

                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <Button className="button back-btn" onClick={ () => this.clearPost() } ><img src={require("../../assets/img/chevron-left.svg").default} alt="" />Back</Button>

                            <h1>Notes</h1>
                            <div className="detail-title">
                                <span className="prev" onClick={ () => this.changePost(-1) }></span>
                                <h2>{this.state.postsData[this.state.selectedPost].note.substring(0,30)} {this.state.postsData[this.state.selectedPost].note.length > 30 && "..."}</h2>
                                <span className="next" onClick={ () => this.changePost(1) }></span>
                            </div>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>


                    <div className="grid-x grid-padding-x detail-container">
                        <div className="small-12 cell">
                            <h2>Note:</h2>
                            <div className="detail-wrap">
                                <div className="grid-x grid-padding-x">
                                    <div className="cell small-12">
                                        <h3>{this.state.postsData[this.state.selectedPost].note}</h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className="grid-x grid-padding-x ">
                        <div className="small-12 cell">
                            <div className="detail-wrap">
                                <div className="grid-x grid-padding-x">
                                    <div className="cell small-12">
                                        {this.state.user.isAdmin === 1 &&
                                        <>
                                            <div className="admin-head">
                                                <h2>Admin - Edit Note</h2>
                                            </div>
                                            <div className="admin-box">
                                                <span className="admintitles">Edit Note</span>
                                                <input type="text" className="settings-field field-space"  name="changedNote" placeholder="note" value={this.state.changedNote} onChange={this.handleChange} />
                                                <button className="button field-space" onClick={() => this.editTitle()}>Update Note</button>
                                                <span className="admintitles">Delete Note</span>
                                                <button className="button delete" onClick={() => this.deletePost()}>Delete This Note</button>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>)
        } else {
            body.push(<div className="main-body">

                <div className="grid-container">

                    <div className="grid-x grid-padding-x header-container">
                        <div className="small-9 cell">
                            <h1>Notes</h1>
                        </div>
                        <div className="small-3">

                        </div>
                    </div>

                    {this.state.user.isAdmin === 1 &&
                    <div className="grid-x grid-padding-x setting-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="admin-head">
                                    <h2>Admin - Change Partner</h2>
                                </div>
                                <div className="admin-box">

                                    <select className="settings-field" value={this.state.partnerID} name="partnerID"
                                            onChange={this.handlePartnerChange}>
                                        {this.state.partners}
                                    </select>
                                </div>
                            </div>

                            <div className="borderless-wrap">
                                <div className="admin-head">
                                    <h2>Admin - Add a New Note</h2>
                                </div>
                                <div className="admin-box">

                                    <span className="admintitles">Note Text</span>

                                    <textarea rows={5}
                                    className="settings-field field-space" name="note" placeholder="note" value={this.state.note} onChange={this.handleChange} />

                                    <button className="button" onClick={() => this.addPost()}>Submit Note</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }


                    <div className="grid-x grid-padding-x date-container">
                        <div className="small-12 cell">
                            <div className="borderless-wrap">
                                <div className="box-head">
                                    <span>Date Range</span>
                                    <PartnerDateSelector overDays={this.state.overDays} fromDate={this.state.fromDate} handleFromDateChange={(e) => this.handleFromDateChange(e)} handleToDateChange={(e) => this.handleToDateChange(e)}
                                                         toDate={this.state.toDate}  handleOverDaysChange={(e) => this.handleOverDaysChange(e)}/>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x stat-container">
                        <div className="small-12 cell">
                            <h2>Note stats during this time period</h2>
                            <div className="quick-stat-wrap">
                                <div className="stat-wrap stat-wrap-1">
                                    <div className="whole-stat">
                                        <div className="stat">{this.state.notes}</div>
                                        <div className="label">Notes</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="grid-x grid-padding-x table-container">
                        <div className="small-12 cell ">
                            <h2>Notes</h2>
                        </div>
                        <div className="small-6 cell table-total">
                            <span>{this.state.postsData ? this.state.postsData.length : "-" } total posts</span>
                        </div>
                        <div className="small-6 cell">
                        </div>

                        <div className="small-6 cell">
                            <input type="text" className="search" placeholder={"Search"}  name="search" value={this.state.search} onChange={this.handleSearchTextChange} />
                        </div>

                        <div className="small-12 cell table">
                            <table>
                                <thead>
                                <th>Notes</th>
                                <th>Date / Time Posted</th>
                                </thead>
                                <tbody>
                                {this.state.bigPostsTable === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.bigPostsTable.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </tbody>
                            </table>
                            <div className="small-12 cell table-nav">
                                {this.state.compsData &&
                                <span>Showing {this.state.postsData.length < this.state.rowsPerPage ? this.state.postsData.length : this.state.rowsPerPage} of {this.state.postsData.length}</span>
                                }
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.postsData === undefined ? 0 : this.state.postsData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(event, newPage) => {

                                        this.setState({page: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({page: 0, rowsPerPage: parseInt(event.target.value, 10)})
                                    }}
                                />
                            </div>
                        </div>


                    </div>

                </div>

            </div>)
        }




        return (
            <>
                <NavBar onUserLogout={this.props.onUserLogout} showModal={this.showModal} section={SECTIONS.REPORTS} onDataSourceChanged={(s) => this.onDataSourceChanged(s)} />
                {body}
                <div className="footer">
                    <Copyright />
                </div>
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </>
        )
    }
}

export default NotesScreen