import React from "react";



class PartnerInfo extends React.Component {

    render() {

        return(<div className="grid-container ">
            <div className="grid-x grid-padding-x">
                <div className="small-4 cell">
                    {this.props.partner.brandImagePath &&
                    <div className="partner-logo">
                        <img src={this.props.partner.brandImagePath} alt=""/>
                    </div>
                    }
                </div>
                <div className="small-4 cell">
                    <h3>Company Address:</h3>
                    {this.props.partner.address1 && <div>{this.props.partner.address1}</div>}
                    {this.props.partner.address2 && <div>{this.props.partner.address2}</div>}
                    {this.props.partner.town && <div>{this.props.partner.town}</div>}
                    {this.props.partner.county && <div>{this.props.partner.county}</div>}
                    {this.props.partner.postcode && <div>{this.props.partner.postcode}</div>}

                </div>
                <div className="small-4 cell partner-info">
                    <h3>Deal End Date:</h3>
                    <div className={"dealdate"}>

                        {this.props.partner.dealEndDate ? this.props.partner.dealEndDate : "-" }

                    </div>

                </div>
            </div>


        </div>)
    }
}

export default PartnerInfo
