import React from 'react'
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import {Link} from "react-router-dom";
import PartnerComponent from "../PartnerComponent";
import { Button } from "react-foundation";


class PasswordResetSubmitScreen extends PartnerComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            token : null,
            isValidating : true,
            isInvalid : false,
            resetSuccess : false
        };
    }


    componentDidMount() {
        if (this.props.match.params.token != null) {
            this.validateTokenOverNetwork(this.props.match.params.token);
        } else {
            this.setState({
                isValidating : false,
                isInvalid : true
            });
        }
    }

    validateTokenOverNetwork(token) {
        var formData = new FormData();
        formData.append("token", token);

        Axios.post(ENDPOINTS.login.validatePasswordReset, formData)
            .then((r) => {
                let resp = API.parseResponse(r);
                this.setState({
                    token : token,
                    isValidating : false,
                    isInvalid : !resp.success
                });
            })
            .catch((e) => {
                this.setState({
                    isValidating : false,
                    isInvalid : true
                });
            });
    }



    requestWasSubmitted() {
        if (this.state.networkInFlight) return;

        var validationResult = this.validateCreateFormData(this.state, [
            {
                key : "password",
                label : "Password",
                postName : "password"
            }
        ]);

        if (validationResult.success) {
            this.setState({
                networkInFlight : true
            });

            let formData = validationResult.formData;
            formData.append("token", this.state.token);

            Axios.post(ENDPOINTS.login.submitPasswordReset, formData)
                .then((response) => {
                    let resp = API.parseResponse(response);
                    if (resp.success) {
                        this.setState({
                            resetSuccess : true
                        });
                    } else {
                        this.showModal("Error", resp.error);
                    }
                })
                .catch((err) => {
                    this.showModal("Error", "An unknown error occurred. [900]");
                });
        } else {
            this.showModal("Error", validationResult.error);
        }
    }



    render() {

        var formFields = [];

        if (this.state.isValidating) {
            formFields.push(
                <div>
                    <div className={"form-block text-center"}><strong>Please wait</strong></div>
                </div>
            );
        } else if (!this.state.isInvalid) {
            if (!this.state.resetSuccess) {
                formFields.push(
                    <div>
                        <label>New Password</label>
                        <input type="password" name="password" className="form-control" value={this.state.password}
                               onChange={this.handleChange}/>
                        <label>Confirm Password</label><br/>
                        <input type="password" name="passwordRetype" className="form-control"
                               value={this.state.passwordRetype} onChange={this.handleChange}/>

                               <Button type="submit" className="button" onClick={ () => this.requestWasSubmitted() } >Change Password</Button>

                        <Link to={"/"} className="forgotpass">Return to Login</Link>
                    </div>
                );
            } else {
                formFields.push(
                    <div>
                        <div><strong>Successfully changed Password. Please <Link to={"/"} className="forgotpass">click here</Link> to login.</strong></div>
                    </div>
                );
            }
        } else {
            formFields.push(
                <div>
                    <div><strong>There was a problem with your Password Reset request. This request may have expired or have been already used. Please request a new Password Reset.</strong></div>
                </div>
            );
        }


        return (<div className="loginbg">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="large-4 medium-2 small-0 cell"></div>
                    <div className="large-4 medium-8 small-12 cell">
                        <img src={require("../../assets/img/gas_app_partners_large_icon.svg").default} alt="Gas App Partners Portal Logo" />

                    </div>
                    <div className="large-4 medium-2 small-0 cell"></div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="large-4 medium-2 small-0 cell"></div>

                    <div className="large-4 medium-8 small-12 cell">
                        <div className="loginbox">
                            <h3>Password Reset</h3>
                            {formFields}

                        </div>
                    </div>
                    <div className="large-4 medium-2 small-0 cell"></div>
                </div>

            </div>
            <AlertModal
                open={ (this.state.modal != null) ? this.state.modal.open : false }
                title={ (this.state.modal != null) ? this.state.modal.title : "" }
                content={ (this.state.modal != null) ? this.state.modal.content : [] }
                buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                dismissHandler={ () => this.modalWasDismissed() }
            />
        </div>)
    }
}

export default PasswordResetSubmitScreen;