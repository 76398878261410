import React from "react";

class Copyright extends React.Component {


    render() {

        return (
            <span>&copy; Gas App Uk {(new Date().getFullYear())}</span>
        );
    }

}

export default Copyright;